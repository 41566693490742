// Dependencies imports
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "../../../config/aws-exports";
import { useTheme } from "@mui/material/styles";
import { useForm } from "../../Hooks/useForm";
import ProStyle from "./ProInvoice.module.scss";

// Style imports
import User from "./ProInvoice.module.scss";
import logo from "../../../assets/USP logo R.png";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2rem",
  },
  header: {
    background: "#DED7CF",
    padding: "0.5rem 2rem",
    "& > h2": {
      margin: 0,
      color: "#E96138",
    },
    "& > div": {
      position: "absolute",
      right: "2rem",
      top: "7rem",
    },
  },
  body: {
    margin: "2rem 6rem",
    "& > h4": {
      textAlign: "center",
      textDecoration: "underline",
    },
  },
  table: {
    border: "3px solid",
    borderCollapse: "collapse",
    width: "100%",
    margin: "1rem 0rem",
    "& > tr > td": {
      border: "2px solid",
      padding: "0px 6px",
    },
    "& > tr > th": {
      border: "2px solid",
      textAlign: "left",
      padding: "0px 6px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem 2rem 2rem",
    borderTop: "3px solid #B26253",
    borderBottom: "1rem solid #E01E5A",
    "& > h3": {
      margin: 0,
    },
  },
  alignRight: {
    textAlign: "right",
  },
  alignCenter: {
    textAlign: "center",
  },
}));

function createData(line, description, HSN, Qty, unit, amount) {
  return { line, description, HSN, Qty, unit, amount };
}

const rows = [
  createData(
    1,
    "USP India - Deviation Investigations Including RCA on July 27, 2022",
    999293,
    7,
    "11,250.00",
    "78,750.00"
  ),
  createData(2, "IGST @ 18%", "", "", "", "14,175.00"),
  createData("", "", "", "", "", "92,925.00"),
];

const ProformaEdit = ({ handleCloseProformaEdit, open }) => {
  const classes = useStyles();
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { handleChange, values, setValues } = useForm({});

  useEffect(() => {
    setValues({
      ...values,
      address: "" || "",
    });
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
    >
      <DialogTitle id="responsive-dialog-title" className="clearfix">
        <IconButton
          onClick={() => {
            handleCloseProformaEdit();
          }}
          style={{ position: "absolute", right: 10, top: 10 }}
        >
          <Close sx={{ fontSize: "2rem" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="overall-container"
        style={{ minHeight: "1245px", width: "100%", overflow: "hidden" }}
      >
        <>
          <div className={classes.container}>
            <div className={classes.header}>
              <h2>USP Education</h2>
              <div>
                <img src={logo} alt="logo" height="80" />
              </div>
            </div>
            <div className={classes.body}>
              <p>16 December 2022</p>
              <h4>Proforma Invoice</h4>
              <span>
                Bill to: -<br />
                <div className={ProStyle.inputholder}>
                  <textarea
                    rows={4}
                    id="address"
                    className={ProStyle.textfield}
                    onChange={handleChange("address")}
                    value={values?.address || ""}
                  ></textarea>
                </div>
              </span>
              <p>
                GST:{" "}
                <div className={ProStyle.inputholder}>
                  <input
                    type="text"
                    id="name"
                    className={ProStyle.inputfield}
                    onChange={handleChange("gst")}
                    value={values?.gst || ""}
                  ></input>
                </div>
              </p>
              <p style={{ margin: "2rem 0rem" }}>
                Payment Terms: 100% Advance Payment
              </p>
              <div>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650, background: "#ededed" }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow sx={{ "th, td": { border: 1.5 } }}>
                        <TableCell>Line</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>HSN</TableCell>
                        <TableCell>Qty.</TableCell>
                        <TableCell>Unit&nbsp;(INR)</TableCell>
                        <TableCell>Amount&nbsp;(INR)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        //sx={{ '&:last-child td, &:last-child th, &:second-child td': { border: 1 } }}
                        sx={{ "th, td": { border: 1.5 } }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          1
                        </TableCell>
                        <TableCell>
                          {" "}
                          <textarea
                            rows={4}
                            id="location"
                            className={ProStyle.textfield}
                            onChange={handleChange("sessionNAme")}
                            value={values?.sessionNAme || ""}
                          ></textarea>
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          <input
                            type="text"
                            id="name"
                            className={ProStyle.inputfield}
                            onChange={handleChange("hsn")}
                            value={values?.hsn || ""}
                          ></input>
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          <input
                            type="text"
                            id="name"
                            className={ProStyle.inputfield}
                            onChange={handleChange("qty")}
                            value={values?.qty || ""}
                          ></input>
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          <input
                            type="text"
                            id="name"
                            className={ProStyle.inputfield}
                            onChange={handleChange("unit")}
                            value={values?.unit || ""}
                          ></input>
                        </TableCell>
                        <TableCell align="right">
                          {" "}
                          <input
                            type="text"
                            id="name"
                            className={ProStyle.inputfield}
                            onChange={handleChange("amount")}
                            value={values?.amount || ""}
                          ></input>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        //sx={{ '&:last-child td, &:last-child th, &:second-child td': { border: 1 } }}
                        sx={{ "th, td": { border: 1.5 } }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          2
                        </TableCell>
                        <TableCell>IGST @ 18%</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="right">
                          <input
                            type="text"
                            id="name"
                            className={ProStyle.inputfield}
                            onChange={handleChange("amountgst")}
                            value={values?.amountgst || ""}
                          ></input>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        //sx={{ '&:last-child td, &:last-child th, &:second-child td': { border: 1 } }}
                        sx={{ "th, td": { border: 1.5 } }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                        ></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="right">
                          <input
                            type="text"
                            id="name"
                            className={ProStyle.inputfield}
                            onChange={handleChange("amountgst")}
                            value={values?.amountgst || ""}
                          ></input>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div>
                <p style={{ margin: "1rem 0rem", fontWeight: "700" }}>
                  INR{" "}
                  <div className={ProStyle.inputholder}>
                    <input
                      type="text"
                      id="name"
                      className={ProStyle.inputfield}
                      onChange={handleChange("inr")}
                      value={values?.inr || ""}
                    ></input>
                  </div>
                </p>
                <table style={{ width: "40%" }}>
                  <tr>
                    <td>USP India GSTIN</td>
                    <td>: 36AAACU7542C1ZI</td>
                  </tr>
                  <tr>
                    <td>USP India PAN No</td>
                    <td>: AAACU7542C</td>
                  </tr>
                </table>

                <p>
                  Nature of services: "Commercial Training and Coaching
                  Services".
                  <br />
                  Please inform us in advance if your unit is in SEZ for GST
                  amount.
                </p>

                <p>
                  Please Remit to:
                  <br />
                  Beneficiary: "United States Pharmacopeia India (P) Ltd."
                  <br />
                  Bank: ICICI Bank Ltd
                  <br />
                  Address: 6-2-1012, TGV Mansion, Khairtabad, Hyderabad.
                  <br />
                  Account: 000805005833
                  <br />
                  IFSC Code: ICIC0000008
                  <br />
                  Swift Code: ICICINBB008
                  <br />
                  Note: Taxes are subject to changes as per Government rules.
                </p>

                <h4 style={{ textAlign: "center", margin: "3rem 0rem" }}>
                  This is an electronic document hence signature is not required
                </h4>
              </div>
            </div>

            <div className={classes.footer}>
              <h3>education.usp.org</h3>
              <h3>Empowering a healthy tomorrow</h3>
            </div>
          </div>
        </>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></DialogActions>
    </Dialog>
  );
};

export default ProformaEdit;
