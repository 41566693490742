import React, { useState } from "react";
import { API } from "aws-amplify";
import config from "../../config/aws-exports";
import { Box, Button } from "@mui/material";

const CSVUploadComponent = ({ sid, getRegisteredUsers, handleClose }) => {
  const [csvData, setCsvData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;
        const rows = content.split("\n");

        // Check if the header contains "emailid"
        const headerRow = rows[0].split(",");
        const emailIdHeaderIndex = headerRow.findIndex((header) =>
          header.toLowerCase().includes("emailid")
        );

        if (emailIdHeaderIndex === -1) {
          alert("Email ID header not found in the CSV file.");
          return;
        }

        // Parse the CSV data starting from the second row and extract unique email addresses
        const uniqueEmailAddressesSet = new Set();
        rows.slice(1).forEach((row) => {
          const email = row.split(",")[emailIdHeaderIndex];
          if (email && email.trim() !== "") {
            uniqueEmailAddressesSet.add(email.trim());
          }
        });

        // Convert the Set to an array
        const uniqueEmailAddresses = Array.from(uniqueEmailAddressesSet);

        // Set the CSV data state
        setCsvData({
          headers: ["Email Address"],
          body: uniqueEmailAddresses.map((email) => ({
            "Email Address": email,
          })),
        });
      };

      reader.readAsText(file);
    } catch (error) {
      console.error("Error handling file upload:", error);
    }
  };

  const uploadComplementaryUsers = async () => {
    setIsLoading(true);
    try {
      const bodyParam = {
        body: {
          emails: csvData?.body.map((email) => email["Email Address"]),
          sid: sid,
        },
        header: {
          "Content-Type": "application/json",
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/register_complementary_users",
        bodyParam
      );

      // Extract relevant information from the response
      const responseData = response?.data;
      const headers = Array.from(responseData.headers);
      const body = responseData.body;
      const emailErrorList = responseData?.body || [];

      // Display the information in a suitable format
      emailErrorList.forEach((entry) => {
        console.log(
          `Email: ${entry["Email Address"]}, Error: ${entry["Error Message"]}`
        );
      });

      // Update state or perform other actions based on your use case
      console.log({ headers, body });
      if (response.statusCode !== 200) {
        setCsvData({ headers, body });
      }
      if (response.statusCode === 200) {
        await getRegisteredUsers();
        setIsLoading(false);
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading complementary users:", error);
      setIsLoading(false);
      throw error;
    }
  };

  return (
    <div>
      <div
        // className={User.field}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          margin: "auto",
          gap: "20px",
          border: "1px solid gray",
          padding: "50px",
          marginBottom: "1.5rem",
        }}
      >
        <input type="file" accept=".csv" onChange={handleFileUpload} />
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Button
          onClick={uploadComplementaryUsers}
          disabled={isLoading}
          variant="contained"
        >
          {isLoading ? "Loading..." : "Upload"}
        </Button>
      </Box>
      {csvData && (
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          <table
            style={{
              width: "100%",
              border: "1px solid #ddd",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                {csvData?.headers
                  ?.filter((f) => f !== "code")
                  .map((header, index) => (
                    <th
                      key={`head-${index}`}
                      style={{
                        textAlign: "left",
                        border: "1px solid #ddd",
                        padding: "8px",
                      }}
                    >
                      {header}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {csvData?.body?.map((email, index) => (
                <tr
                  key={`body-${index}`}
                  style={{
                    backgroundColor: "white",
                    color:
                      email["code"] === 200
                        ? "green"
                        : email["code"] === 500
                        ? "red"
                        : "",
                  }}
                >
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    {email["Email Address"]}
                  </td>
                  {email["Message"] && (
                    <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                      {email["Message"]}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CSVUploadComponent;
