import React, { useEffect, useState } from "react";

import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";

import User from "./AddUsersTenant.module.scss";
import { Constants } from "../../../config/Constants";
import config from "../../../config/aws-exports";
import { API, JS } from "aws-amplify";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
}));

const UserBulkUploadTenant = (props) => {
  let { handleClose, type, getUsers } = props;
  const [spin, setSpin] = useState(false);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState("");
  const [csvArray, setCsvArray] = useState([]);
  const [fileErr, setFileErr] = useState();
  const [userData, setUserData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  let userDetails = useSelector(authData);
  console.log(userDetails, "userdetails");
  const onsubmit = () => {
    // let formIsValid = true;

    setUserData([]);
    setErrorData([]);
    setFileErr("");
    var temp = [];
    var temp2 = [];
    if (csvFile === undefined || csvFile === "") {
      setFileErr("Please select csv file to upload");
      return;
    }
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (csvArray.length > 0) {
      for (var i = 0; i < csvArray.length; i++) {
        if (
          csvArray[i].Firstname !== undefined &&
          csvArray[i].Firstname !== "" &&
          csvArray[i].Lastname !== undefined &&
          csvArray[i].Lastname !== "" &&
          csvArray[i].Email !== undefined &&
          csvArray[i].Email !== "" &&
          csvArray[i].Designation !== undefined &&
          csvArray[i].Designation !== "" &&
          csvArray[i].Department !== undefined &&
          csvArray[i].Department !== "" &&
          csvArray[i].Organization !== undefined &&
          csvArray[i].Organization !== "" &&
          csvArray[i].gender !== undefined &&
          csvArray[i].gender !== "" &&
          csvArray[i].Role !== undefined &&
          csvArray[i].Role !== "" &&
          (csvArray[i].Role === "User" || csvArray[i].Role === "Manager") &&
          pattern.test(csvArray[i].Email)
        ) {
          temp.push(csvArray[i]);
        } else {
          temp2.push(csvArray[i]);
        }
      }
    }
    setErrorData(temp2);
    if (csvArray.length === 0) {
      setFileErr("File has no records");
      return;
    }
    if (temp2.length === 0) {
      if (type !== "batch") {
        changeFormat(temp);
        // Updateuser(temp);
      } else {
        Updatebatchuser(temp);
      }
    }
  };

  function changeFormat(temp) {
    console.log("temp", temp);

    for (let i = 0; i < temp.length; i++) {
      let obj = {};
      obj.oid = config.aws_org_id;
      obj.ur_id = 0;
      obj.tenant = userDetails.tenantdata.tenantname;
      obj.emailid = temp[i].Email;
      obj.designation = temp[i].Designation;
      obj.department = temp[i].Department;
      obj.organization = temp[i].Organization;
      obj.first_name = temp[i].Firstname;
      obj.last_name = temp[i].Lastname;
      obj.dev = 2;
      obj.gen = temp[i].gender == "Male" || temp[i].gender == "male" ? 1 : 2;
      if (temp[i].Role.toLowerCase() === "User".toLowerCase()) {
        obj.urole = "User";
      } else if (temp[i].Role.toLowerCase() === "Manager".toLowerCase()) {
        obj.urole = "Manager";
      } else {
        obj.urole = null;
      }

      obj.st_id = userDetails.tenantdata.st_id;
      obj.address = temp[i].address;
      obj.uid = "";
      obj.cno = temp[i].Contactno == "" ? null : temp[i].Contactno;
      obj.schema = Constants.SCHEMA_NAME;
      obj.atype = 0;
      setSpin(true);
      Updateuser(obj, i, temp.length - 1);
    }
  }

  async function Updateuser(obj, i, l) {
    const bodyParam = {
      body: obj,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    console.log("bd" + JSON.stringify(bodyParam.body));
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.ADD_USER,
        bodyParam
        //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
      );
      // const users = response;
      console.log(JSON.stringify(response));
      if (i == l) {
        handleClose();
        getUsers();
        swal({
          title: "Success",
          text: response.message,
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            handleClose();
            // getUser();
          }
        });
      }
    } catch (error) {
      getUsers();
      setSpin(false);
      if (i == l) {
        setSpin(false);
        handleClose();
        swal({
          title: "Success",
          text: "All users added successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            getUsers();
            handleClose();
            setSpin(false);
          }
        });
      }
      // swal({
      //   title: "Oooops!",
      //   text: "Something went wrong!",
      //   icon: "warning",
      //   dangerMode: true,
      // }).then((willDelete) => {
      //   if (willDelete) {
      //     setSpin(false);
      //   }
      // });
      console.log("getUserError", error);
    }
    console.log("Mount");
  }

  // async function Updateuser(afile) {
  //   setSpin(true);
  //   const bodyParam = {
  //     body: {
  //       oid: config.aws_org_id,
  //       jsonfile: afile,
  //       tenant: userDetails.locale
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   };

  //   try {
  //     const response = await API.post(
  //       config.aws_cloud_logic_custom_name,
  //       '/addBulkUsers',
  //       bodyParam
  //       //`${Constants.GET_MY_PROGRAMS}`, bodyParam,
  //     );

  //     if (response != null && response.code == 0) {
  //       setSpin(false);
  //       setUserData(response.data);
  //     } else {
  //       swal({
  //         title: "Success",
  //         text: "Users added successfully",
  //         icon: "success",
  //         dangerMode: false,
  //       }).then((willDelete) => {
  //         if (willDelete) {
  //           setSpin(false);
  //           window.location.reload("./users");
  //         }
  //       });
  //       handleClose();
  //     }
  //   } catch (error) {
  //     setSpin(false);
  //     swal({
  //       title: "Oooops!",
  //       text: "Something went wrong!",
  //       icon: "warning",
  //       dangerMode: true,
  //     }).then((willDelete) => {
  //       if (willDelete) {
  //         setSpin(false);
  //       }
  //     });
  //     console.log("getUserError", error);
  //   }
  //   console.log("Mount");
  // }

  async function Updatebatchuser(afile) {
    let sdata = { ...userDetails };
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        jsonfile: afile,
        action: "add",
        tenant: userDetails.locale,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        `/addBulkUserToBatch`,
        bodyParam
      );

      if (response != null && response.code == 0) {
        setSpin(false);
        setUserData(response.data);
      } else {
        swal({
          title: "Success",
          text: "Users added successfully",
          icon: "success",
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            window.location.reload("./users");
          }
        });
        handleClose();
      }
    } catch (error) {
      console.error(error);
      swal({
        title: "Oooops!",
        text: "Soomthing wrong!",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Contact Developer");
          setSpin(false);
        }
      });
    }
  }
  function isHTML(filename) {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "csv":
        return true;
      default:
        return false;
    }
  }
  function getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!isHTML(e.target.files[0].name)) {
      swal("Oops!", "Select correct file format..!", "error");
      return;
    }
    setCsvFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      const xd = e.target.result;
      console.log(xd);
      var array = CSVToArray(xd);
      var objArray = [];
      for (var i = 1; i < array.length; i++) {
        objArray[i - 1] = {};
        for (var k = 0; k < array[0].length && k < array[i].length; k++) {
          var key = array[0][k];
          if (array[i][k] != undefined) {
            objArray[i - 1][key] =
              key == "Email" ? array[i][k].toLowerCase() : array[i][k];
          }
        }
      }
      var filteredarray;
      if (type != "batch") {
        filteredarray = objArray.filter(
          (key) =>
            key.Firstname != undefined &&
            key.Lastname != undefined &&
            key.Email != undefined &&
            key.Designation != undefined &&
            key.Department != undefined &&
            key.Organization != undefined &&
            key.Contactno != undefined &&
            key.gender != undefined &&
            key.address != undefined &&
            key.Role != undefined &&
            key.Firstname != " " &&
            key.Lastname != " " &&
            key.Email != " " &&
            key.Department != " " &&
            key.Designation != " " &&
            key.Organization != " " &&
            key.Contactno != " " &&
            key.gender != " " &&
            key.address != " " &&
            key.Role != " "
        );
      } else {
        filteredarray = objArray.filter(
          (key) =>
            key.Firstname != undefined &&
            key.Lastname != undefined &&
            key.Email != undefined &&
            key.Role != undefined &&
            key.Firstname != " " &&
            key.Email != " " &&
            key.Role != " "
        );
      }

      setCsvArray(filteredarray);
    };
    reader.readAsText(file);
  };

  const CSVToArray = function (strData, strDelimiter) {
    strDelimiter = strDelimiter || ",";

    var objPattern = new RegExp(
      "(\\" +
        strDelimiter +
        "|\\r?\\n|\\r|^)" +
        '(?:"([^"]*(?:""[^"]*)*)"|' +
        '([^"\\' +
        strDelimiter +
        "\\r\\n]*))",
      "gi"
    );

    var arrData = [[]];

    var arrMatches = null;

    while ((arrMatches = objPattern.exec(strData))) {
      var strMatchedDelimiter = arrMatches[1];

      if (strMatchedDelimiter.length && strMatchedDelimiter != strDelimiter) {
        arrData.push([]);
      }

      if (arrMatches[2]) {
        var strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
      } else {
        // We found a non-quoted value.
        strMatchedValue = arrMatches[3].length == 0 ? "" : arrMatches[3];
      }

      arrData[arrData.length - 1].push(strMatchedValue);
    }

    return arrData;
  };
  return (
    <div className={User.maindiv}>
      {spin === false ? (
        <div className={User.maincontainer}>
          <div className={User.iconcontainer}>
            <h3 className={User.create}>Bulk Upload</h3>
            <Close className={User.closebutton} onClick={handleClose} />
          </div>

          <div className={User.form}>
            <div
              style={{
                color: "#cc0000",
                marginBottom: "12px",
                marginTop: "-10px",
                textAlign: "start",
                fontSize: "13px",
              }}
            >
              <p>{fileErr}</p>
            </div>
            <div
              className={User.field}
              style={{
                border: "1px solid gray",
                padding: "50px",
                display: "block",
              }}
            >
              <input
                type="file"
                id="files"
                accept=".csv"
                onChange={(e) => handleFileUpload(e)}
              />
              {/* <label for="files">
                <Button variant="contained" color="primary" component="span">
                  Browse
                </Button>
              </label> */}
            </div>

            <br></br>

            {userData.length > 0 ? (
              <>
                {type !== "batch" ? (
                  <p style={{ fontSize: "14px", textAlign: "left" }}>
                    These users are already exist in portal. Please remove from
                    file and upload again.
                  </p>
                ) : (
                  <p style={{ fontSize: "14px", textAlign: "left" }}>
                    These users are already exist in batch. Please remove from
                    file and upload again.
                  </p>
                )}
                <div
                  style={{
                    height: "200px",
                    overflow: "scroll",
                    marginTop: "10px",
                  }}
                >
                  {type !== "batch" ? (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Contanct No</th>
                        <th>Gender</th>
                        <th>Role</th>
                      </thead>
                      <tbody>
                        {userData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.Firstname}</td>
                            <td>{item.lastname}</td>
                            <td>{item.ADS}</td>
                            <td>{item.CNO}</td>
                            <td>{item.GEN}</td>
                            <td>{item.ROLE}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Email</th>
                      </thead>
                      <tbody>
                        {userData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.first_name}</td>
                            <td>{item.last_name}</td>
                            <td>{item.EMAILID}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ) : null}
            {errorData.length > 0 ? (
              <>
                <p style={{ fontSize: "14px", textAlign: "left" }}>
                  Below are the invalid entries please remove from file and
                  upload again.
                </p>

                <div
                  style={{
                    height: "200px",
                    overflow: "scroll",
                    marginTop: "10px",
                  }}
                >
                  {type != "batch" ? (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>FirstName</th>
                        <th>LastName</th>
                        <th>Email</th>
                        <th>Designation</th>
                        <th>Department</th>
                        <th>Organization</th>
                        <th>Contact No</th>
                        <th>Gender</th>
                        <th>Address</th>
                        <th>Role</th>
                      </thead>
                      <tbody>
                        {errorData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.Firstname}</td>
                            <td>{item.Lastname}</td>
                            <td>{item.Email}</td>
                            <td>{item.Designation}</td>
                            <td>{item.Department}</td>
                            <td>{item.Organization}</td>
                            <td>{item.Contactno}</td>
                            <td>{item.gender}</td>
                            <td>{item.address}</td>
                            <td>{item.Role}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table style={{ fontSize: "14px", width: "100%" }}>
                      <thead style={{ backgroundColor: "aliceblue" }}>
                        <th>Name</th>
                        <th>Email</th>
                      </thead>
                      <tbody>
                        {errorData.map((item, i) => (
                          <tr key={i}>
                            <td>{item.Firstname}</td>
                            <td>{item.LastName}</td>
                            <td>{item.Email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </>
            ) : null}
            <br></br>
            <input
              type="submit"
              value="Save"
              onClick={onsubmit}
              className={
                window.navigator.onLine === true
                  ? User.btn_color
                  : User.btn_colordis
              }
              disabled={!window.navigator.onLine}
            ></input>
          </div>
        </div>
      ) : (
        <div className={classes.root}>
          <CircularProgress style={{ color: config.main_color_1 }} />
        </div>
      )}
    </div>
  );
};

export default UserBulkUploadTenant;
