// Dependencies import
import React, { useState, useRef } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import swal from "sweetalert";
import swal1 from "@sweetalert/with-react";

import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import axios from "axios";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { generate } from "shortid";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import { Constants } from "../../../config/Constants";

// Style imports
import Add from "./SessionAdd.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

function convert(timestamp) {
  let ts = parseInt(timestamp);
  var date_not_formatted = new Date(ts);

  var formatted_string = date_not_formatted.getFullYear() + "-";

  if (date_not_formatted.getMonth() < 9) {
    formatted_string += "0";
  }
  formatted_string += date_not_formatted.getMonth() + 1;
  formatted_string += "-";

  if (date_not_formatted.getDate() < 10) {
    formatted_string += "0";
  }
  formatted_string += date_not_formatted.getDate();
  formatted_string += "T";
  if (date_not_formatted.getHours() < 10) {
    formatted_string += "0";
  }
  formatted_string += date_not_formatted.getHours();
  formatted_string += ":";
  if (date_not_formatted.getMinutes() < 10) {
    formatted_string += "0";
  }
  formatted_string += date_not_formatted.getMinutes();

  return formatted_string;
}
function convert1(timestamp) {
  let ts = parseInt(timestamp);
  var date_not_formatted = new Date(ts);

  var formatted_string = date_not_formatted.getFullYear() + "-";

  if (date_not_formatted.getMonth() < 9) {
    formatted_string += "0";
  }
  formatted_string += date_not_formatted.getMonth() + 1;
  formatted_string += "-";

  if (date_not_formatted.getDate() < 10) {
    formatted_string += "0";
  }
  formatted_string += date_not_formatted.getDate();

  return formatted_string;
}
const getExtension = (filename) => {
  let parts = filename.split(".");
  return parts[parts.length - 1];
};

export const isPdf = (filename) => {
  var ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case "pdf":
      return true;
    default:
      return false;
  }
};

const SessionAdd = ({
  handleAddLiveClose,
  getSessionList,
  speakerList,
  sessionList,
  spin,
  setSpin,
}) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [startdate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  //const [imageError, setImageError] = useState("");
  const [imageExtentionError, setImageExtentionError] = useState("");

  const [docExtentionError, setDocExtentionError] = useState("");
  const [sessionTypeCheck, setSessionTypeCheck] = useState(true);
  const [paymentTypeCheck, setPaymentTypeCheck] = useState(true);
  const [startTimeError, setStartTimeError] = useState("");
  const [wrongDateError, setWrongDateError] = useState("");
  const [wrongStartDateError, setWrongStartDateError] = useState("");
  const [slots, setSlots] = useState(0);
  const [courseID, setCourseID] = useState("");
  const [courseIdError, setCourseIdError] = useState("");
  const [dumslots, setDumslots] = useState(0);
  const [slides, setSlides] = useState("");
  const [onChangeSlide, setOnChangeSlides] = useState(false);
  const [sessionType, setSessionType] = useState("online");
  const [speakerType, setSpeakerType] = useState("");
  const [regStartDate, setRegStartDate] = useState("");
  const [webLink, setWebLink] = useState("");
  const [webID, setWebID] = useState("");
  const [passcode, setPasscode] = useState("");
  const [note, setNote] = useState("");
  const [regStartDateError, setRegStartDateError] = useState("");
  const [paid, setPaid] = useState("no");
  const [actualPriceINR, setActualPriceINR] = useState("");
  const [actualPriceUSD, setActualPriceUSD] = useState("");
  const [perDis, setPerDis] = useState("");
  const [priceAftINR, setPriceAftINR] = useState(0);
  const [priceAftUSD, setPriceAftUSD] = useState(0);
  const [earlyBirdINR, setEarlyBirdINR] = useState("");
  const [earlyBirdUSD, setEarlyBirdUSD] = useState("");
  const [earlyDate, setEarlyDate] = useState("");
  const [payLater, setPayLater] = useState(false);
  const [payWithin, setPayWithin] = useState("");
  const [des, setDes] = useState("");
  const [object, setObject] = useState("");
  const [offline, setOffline] = useState(false);
  const [locationValue, setLocationValue] = useState("");
  const [location, setLocation] = useState(false);
  const [slotsFree, setSlotsFree] = useState(true);
  const [target, setTarget] = useState("");
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const [totalSlotsError, setTotalSlotsError] = useState("");
  const [sessionStartTimeError, setSessionStartTimeError] = useState("");
  const [sessionEndTimeError, setSessionEndTimeError] = useState("");
  const [topayment, setTopayment] = useState(false);
  const [payLaterError, setPayLaterError] = useState("");
  const [speakerError, setSpeakerError] = useState("");
  const [speakerTypeError, setSpeakerTypeError] = useState("");
  const [earlyError, setEarlyError] = useState("");
  const [earlyDateError, setEarlyDateError] = useState("");
  const [slotsError, setSlotsError] = useState("");
  const [slidesError, setSlidesError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [sessionTypeError, setSessionTypeError] = useState("");
  const [webLinkError, setWebLinkError] = useState("");
  const [webLinkFormatError, setWebLinkFormatError] = useState("");
  const [paidError, setPaidError] = useState("");
  const [actualPriceError, setActualPriceError] = useState("");
  const [payLaterDateError, setPayLaterDateError] = useState("");
  const [view, setView] = useState(false);

  const [sessionFileName, setSessionFileName] = useState([]);
  //const [spin, setSpin] = useState(false);
  const [speakerArray, setSpeakerArray] = useState([]);
  const [defaultSpeaker, setDefaultSpeaker] = useState(true);
  const [radioOffilne, setRadioOffline] = useState(false);
  const [radioHybrid, setRadioHybrid] = useState(false);
  const [copyDetails, setCopyDetails] = useState(false);
  const [copyValue, setCopyValue] = useState("");
  const [agendaFileChanges, setAgendaFileChanges] = useState(false);
  const [dateList, setDateList] = useState([
    {
      id: generate(),
      date: "",
      sTime: "",
      eTime: "",
      min: new Date().toISOString().slice(0, 10),
    },
  ]);

  const [agendaFiles, setAgendaFiles] = useState([]);
  const [certificateFile, setCertificateFile] = useState();

  const refselectedImageFile = useRef();
  const refselectedSlideFile = useRef();
  const classes = useStyles();

  const onChangeHandlerName = (event) => {
    setName(event.target.value);
  };
  const onChangeHandlerNote = (event) => {
    setNote(event.target.value);
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  const isDoc = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      case "mp4":
        return true;
      case "mov":
        return true;
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  const onChangeHandlerImage = (event) => {
    if (!isImage(event.target.files[0].name)) {
      setImageExtentionError(
        "Wrong file format! Accepted file format: .png/.jpeg/.jpg"
      );
      return;
    }
    setImageExtentionError("");
    setImage({ file: event.target.files[0] });
    refselectedImageFile.current = event.target.files[0];
  };

  const onChangeHandlerSlides = (event) => {
    if (!isDoc(event.target.files[0].name)) {
      setDocExtentionError(
        "Wrong file format! Accepted file format: .pdf, .mp4, .jpg, .jpeg, .png"
      );
      return;
    }
    setDocExtentionError("");
    setOnChangeSlides(true);
    setSlides({ file: event.target.files[0] });
    refselectedSlideFile.current = event.target.files[0];

    if (
      certificateFile !== undefined &&
      Array.isArray(certificateFile) &&
      certificateFile.length > 0
    ) {
      setCertificateFile((cFile) => [...cFile, event.target.files[0]]);
    } else {
      setCertificateFile([event.target.files[0]]);
    }
  };
  const onChangeHandlerAgenda = (event) => {
    setDocExtentionError("");
    const filesArray = Array.from(event.target.files);

    filesArray.forEach((file) => {
      console.log(file.name);
      if (!isPdf(file.name)) {
        return setDocExtentionError(
          "Wrong file format! Accepted file format: .pdf"
        );
      }
    });
    setAgendaFileChanges(true);
    setAgendaFiles(filesArray);
  };

  const finalSessionImage = async (sidGot) => {
    setSpin(true);
    if (image.file == null) {
      setSpin(false);
      return;
    }
    if (image.file.size > 5242880) {
      setSpin(false);
      setImageError("Please upload a session image less than 5mb");
      swal1(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );

      return;
    }
    if (image.file !== undefined) {
      let imageName = sidGot + ".png";

      var body = {
        type: "sessionimage",
        oid: config.aws_org_id,
        filetype: refselectedImageFile.current.type,
        filename: sidGot + ".png",
      };
      if (agendaFileChanges) {
        agendaFiles.forEach(async (e) => {
          var body = {
            type: "agendadoc",
            filetype: e.type,
            filename: e.name,
            oid: config.aws_org_id,
            sid: sidGot,
          };
          await uploadfile(body, e);
        });
      }
      await uploadfile(body, refselectedImageFile.current);
      if (onChangeSlide === true) {
        await finalSessionSlide(imageName, sidGot);
      } else {
        await addSession(imageName, undefined, sidGot);
      }

      setAgendaFileChanges(false);
    }
  };

  const finalSessionSlide = async (imageName, sidGot) => {
    setSpin(true);
    if (slides.file == null) {
      setSpin(false);
      return;
    }
    if (slides.file.size > 5242880) {
      setSpin(false);
      setSlidesError("File size cannot be greater than 5mb.");
      swal1(
        "Oops!",
        "File size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    let uniqueStamp = new Date().getTime();

    for (var a = 0; a < certificateFile.length; a++) {
      let fileExtension = certificateFile[a].name.substr(
        certificateFile[a].name.lastIndexOf(".") + 1
      );
      let slideName = sidGot + uniqueStamp + "." + fileExtension;
      sessionFileName.push(slideName);
      var body = {
        type: "sessiondoc",
        filetype: certificateFile[a].type,
        filename: slideName,
        oid: config.aws_org_id,
      };
      await uploadfile(body, certificateFile[a]);
    }
    await addSession(imageName, sessionFileName, sidGot);
  };

  async function uploadfile(body, file) {
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        bodyParam
      );

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          console.error(error);
          swal1("Oops!", `${error.message}`, "error");
          return;
        });

      //   setIstLoading(false);
    } catch (error) {
      console.error(error);
      swal1("Oops!", `${error.message}`, "error");
      return;
    }
  }

  const onChangeHandlerSpeakerType = (event) => {
    setSpeakerType(event.target.value);
  };

  const onChangeHandlerSpeaker = (event) => {
    setDefaultSpeaker(false);

    let i = event.target.value;
    let speakArray = [...speakerArray];
    let obj;

    obj = speakerList[i].sp_id;
    let flag = false;
    for (let j = 0; j < speakArray.length; j++) {
      if (speakArray[j] == obj) {
        flag = true;
      }
    }
    if (flag === false) {
      speakArray.push(obj.toString());
    }
    setSpeakerArray(speakArray);
  };

  const removeSpeaker = (value) => {
    setDefaultSpeaker(true);
    let updatedArray = [...speakerArray];
    updatedArray = updatedArray.filter(
      (deleting) => deleting !== value.toString()
    );

    setSpeakerArray(updatedArray);
  };

  const onChangeHandlerSlots = (event) => {
    setSlots(event.target.value);
    setDumslots(event.target.value);
  };

  const onChangeHandleLocation = (event) => {
    setLocationValue(event.target.value);
  };

  const onChangeHandlerRegStartDate = (event) => {
    setRegStartDate(event.target.value);
  };

  const onChangeHandlerWebLink = (event) => {
    setWebLink(event.target.value);
  };

  const onChangeHandlerWebID = (event) => {
    setWebID(event.target.value);
  };

  const onChangeHandlerPasscode = (event) => {
    setPasscode(event.target.value);
  };
  const handleCopySession = (e) => {
    const { value } = e.target;
    setCopyValue(value);
    setCopyDetails(true);
    const filterSessionById = sessionList.filter((item) => item.sid === value);
    const session = filterSessionById[0];

    setName(session?.sn);
    setCourseID(session?.msid);
    setSpeakerArray(session?.speaker_id);
    setSpeakerType(session?.speaker_type);

    setWebID(session?.web_id);
    setWebLink(session?.weblink);
    setPasscode(session?.web_pass);
    setNote(session?.note);

    if (
      !session?.location_value === "" ||
      !session?.location_value === undefined ||
      !session?.location_value === null
    ) {
      setLocationValue(session?.location_value);
    } else {
      setLocationValue("");
    }

    setStartDate(
      session?.startdate === null ? null : convert(session?.startdate)
    );
    setEndDate(session?.enddate === null ? null : convert(session?.enddate));
    setDes(session?.sdesc);
    setObject(session?.lobj);
    setTarget(session?.taudi);

    if (session?.slide_oname === null) {
      setSlides("");
    } else {
      setSlides(session?.slide_oname);
    }

    if (session?.oimgname === null) {
      setSlides("");
    } else {
      setImage(session?.oimgname);
    }
    if (session?.stype === 1) {
      setSessionType("online");
      setLocation(false);
      setSessionTypeCheck(true);
      setRadioOffline(false);
      setOffline(false);
      setRadioHybrid(false);
    } else if (session?.stype === 2) {
      setSessionType("offline");
      setSessionTypeCheck(false);
      setOffline(true);
      setLocation(true);
      setRadioOffline(true);
      setRadioHybrid(false);
    } else if (session?.stype === 3) {
      setSessionType("Hybrid");
      setOffline(false);
      setLocation(true);
      setRadioHybrid(true);
      setRadioOffline(false);
      setSessionTypeCheck(false);
    }

    if (session?.paid == "true") {
      setPaid("yes");
      setView(true);
      setPaymentTypeCheck(false);
      setTopayment(true);
    } else if (session?.paid == "false") {
      setPaid("no");
      setView(false);
      setPaymentTypeCheck(true);
      setTopayment(false);
    }

    setDumslots(session?.tslots);
    setSlots(session?.tslots);
    /*  if (session?.DTLS.PAID === "yes") { */
    setActualPriceINR(session?.priceinr);
    setActualPriceUSD(session?.priceusd);
    setPerDis(session?.perdis);
    setPriceAftINR(session?.priceafterinr);
    setPriceAftUSD(session?.priceafterusd);
    setEarlyBirdINR(session?.earlypriceinr);
    setEarlyBirdUSD(session?.earlypriceusd);
    if (session?.pay_within == null) {
      setPayWithin(null);
    } else {
      setPayWithin(convert1(session?.pay_within));
    }

    setEarlyDate(convert1(session?.earlydate));
    setPayLater(session?.paylater);
    setPayWithin(convert1(session?.pay_within));

    if (session?.stype === 2 || session?.stype === 3) {
      setLocation(true);
      setLocationValue(session?.location_value);
    }
    if (session?.stype === 1) {
      setSessionType("online");
    }
  };
  const onChangeHandlerPaid = (event) => {
    setPaid(event.target.value);

    if (topayment === true) {
      setTopayment(false);
    } else {
      setTopayment(true);
    }
    if (event.target.value == "no" && copyDetails) {
      setDumslots(0);
      setSlots(0);

      setActualPriceINR("");
      setActualPriceUSD("");
      setPerDis("");
      setPriceAftINR(0);
      setPriceAftUSD(0);
      setEarlyBirdINR("");
      setEarlyBirdUSD("");

      setPayWithin("");

      setEarlyDate("");
      setPayLater(false);
    } else if (event.target.value === "yes" && copyDetails) {
      const filterSessionById = sessionList?.filter(
        (item) => item?.sid === copyValue
      );
      const session = filterSessionById[0];
      setDumslots(session?.tslots);
      setSlots(session?.tslots);
      /*  if (session?.DTLS.PAID === "yes") { */
      setActualPriceINR(session?.priceinr);
      setActualPriceUSD(session?.priceusd);
      setPerDis(session?.perdis);
      setPriceAftINR(session?.priceafterinr);
      setPriceAftUSD(session?.priceafterusd);
      setEarlyBirdINR(session?.earlypriceinr);
      setEarlyBirdUSD(session?.earlypriceusd);
      if (session?.pay_within == null) {
        setPayWithin(null);
      } else {
        setPayWithin(convert1(session?.pay_within));
      }

      setEarlyDate(convert1(session?.earlydate));
      setPayLater(session?.paylater);
      setPayWithin(convert1(session?.pay_within));
    }
  };

  const onChangeHandlerActualPriceINR = (event) => {
    setActualPriceINR(event.target.value);
  };

  const onChangeHandlerActualPriceUSD = (event) => {
    setActualPriceUSD(event.target.value);
  };

  const onChangeHandlerPerDis = (event) => {
    setPerDis(event.target.value);
    let discountedPriceINR =
      ((100 - event.target.value) / 100) * actualPriceINR;
    let discountedPriceUSD =
      ((100 - event.target.value) / 100) * actualPriceUSD;
    if (event.target.value === "") {
      setPriceAftINR(0);
      setPriceAftUSD(0);
    } else {
      setPriceAftINR(discountedPriceINR);
      setPriceAftUSD(discountedPriceUSD);
    }
  };

  const onChangeHandlerEarlyBirdINR = (event) => {
    setEarlyBirdINR(event.target.value);
  };

  const onChangeHandlerEarlyBirdUSD = (event) => {
    setEarlyBirdUSD(event.target.value);
  };

  const onChangeHandlerEarlyDate = (event) => {
    setEarlyDate(event.target.value);
  };

  const onChangeHandlerPayLater = (event) => {
    if (event.target.checked === true) {
      setPayLater(true);
    } else {
      setPayLater(false);
    }
  };

  const onChangeHandlerPayWithin = (event) => {
    setPayWithin(event.target.value);
  };

  const onChangeHandlerDes = (event) => {
    setDes(event.target.value);
  };

  const onChangeHandlerObject = (event) => {
    setObject(event.target.value);
  };

  const onChangeHandlerTarget = (event) => {
    setTarget(event.target.value);
  };

  function handleOnlineChange(e) {
    setSessionType(e.target.value);
    setLocation(false);
    setSessionTypeCheck(true);
    setRadioOffline(false);
    setOffline(false);
    setRadioHybrid(false);
  }

  function handleOfflineChange(e) {
    setSessionType(e.target.value);
    setSessionTypeCheck(false);
    setOffline(true);
    setLocation(true);
    setRadioOffline(true);
    setRadioHybrid(false);
  }

  function handleHybridChange(e) {
    setSessionType(e.target.value);
    setOffline(false);
    setLocation(true);
    setRadioHybrid(true);
    setRadioOffline(false);
    setSessionTypeCheck(false);
  }

  const submitForm = () => {
    let formIsValid = true;
    setNameError("");
    setCourseIdError("");
    setImageError("");
    setEarlyError("");
    setEarlyDateError("");
    setSessionStartTimeError("");
    setStartTimeError("");
    setWrongDateError("");
    setTotalSlotsError("");
    setSessionEndTimeError("");
    setSpeakerTypeError("");
    setSpeakerError("");
    setRegStartDateError("");
    setSlotsError("");
    setSlidesError("");
    setLocationError("");
    setSessionTypeError("");
    setWebLinkError("");
    setWebLinkFormatError("");
    setPaidError("");
    setActualPriceError("");
    setPayLaterError("");

    setDocExtentionError("");
    setPayLaterDateError("");
    setImageExtentionError("");
    setDocExtentionError("");
    setWrongStartDateError("");

    if (name === "" || name === null || name.trim() === "") {
      formIsValid = false;
      setNameError("Please enter session name");
      return;
    }
    if (courseID === "" || courseID === null || courseID.trim() === "") {
      formIsValid = false;
      setCourseIdError("Please enter Course ID");
      return;
    }
    if (image === null || image.file === undefined) {
      formIsValid = false;
      setImageError("Please upload a session image");
      return;
    }

    if (speakerType === "" || speakerType === null) {
      formIsValid = false;
      setSpeakerTypeError("Please specify the type of Speaker");
      return;
    }
    if (speakerArray.length === 0) {
      formIsValid = false;
      setSpeakerError("Please select a speaker");
      return;
    }
    if (slots === "") {
      formIsValid = false;
      setTotalSlotsError("Keep it 0 to remove slots limit");
      return;
    }
    if (slots !== "") {
      if (slots < 0) {
        formIsValid = false;
        setTotalSlotsError("Slots cannot be in negative");
        return;
      }
    }
    if (paid === "yes" && slots === 0) {
      formIsValid = false;
      setTotalSlotsError("Slots cannot be in 0 if the session is paid");
      return;
    }
    if (paid === "no" && slots !== 0) {
      formIsValid = false;
      setTotalSlotsError("Slots should be 0 if the session is free");
      return;
    }
    if (location === true) {
      if (locationValue.trim("") === "" || locationValue === "") {
        formIsValid = false;
        setLocationError("Please enter the location");
        return;
      }
    }
    if (regStartDate !== "") {
      if (
        new Date(regStartDate).getTime() >
        new Date(dateList[0].combineStartTime).getTime()
      ) {
        formIsValid = false;
        setRegStartDateError(
          "Registration start date should be before the session"
        );
        return;
      }
    }

    if (paid === "yes") {
      if (actualPriceINR === "" || actualPriceINR === null) {
        formIsValid = false;
        setActualPriceError("Please add the actual sesssion price");
        return;
      }
    }
    if (payLater === true) {
      if (payWithin === "") {
        formIsValid = false;
        setPayLaterError("Please select a date");
        return;
      }
      if (
        new Date(dateList[0].combineStartTime).getTime() <
        new Date(payWithin).getTime()
      ) {
        formIsValid = false;
        setPayLaterDateError("Date should be before the session");
        return;
      }
    }
    if (earlyBirdINR !== "") {
      if (earlyDate === "") {
        formIsValid = false;
        setEarlyError("Please select early bird end date");
        return;
      }
      if (
        new Date(earlyDate).getTime() >
        new Date(dateList[0].combineStartTime).getTime()
      ) {
        formIsValid = false;
        setEarlyDateError("Date should be before the session date");
        return;
      }
    }
    if (formIsValid === true) {
      getSessionID();
    }
  };

  async function getSessionID() {
    setSpin(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        type: "session",
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TOPIC_ID,
        bodyParam
      );

      let sidGot = list.body[0].sid;
      finalSessionImage(sidGot);
    } catch (error) {
      console.error(error);
      setSpin(false);
    }
  }

  const addSession = async (imgName, sldName, sidGot) => {
    setSpin(true);
    let bodyObj = {};
    bodyObj.sid = sidGot;
    bodyObj.msid = courseID.replace("'", "''").trim();
    bodyObj.timgname = imgName;
    bodyObj.oimgname = image.file.name.replace("'", "''").trim();
    bodyObj.startdate = startdate === "" ? "" : new Date(startdate).getTime();
    bodyObj.enddate = endDate === "" ? "" : new Date(endDate).getTime();
    bodyObj.speakerType = speakerType;
    bodyObj.speaker_id = speakerArray;
    bodyObj.tslots = parseInt(slots);

    bodyObj.slides = sldName;
    bodyObj.agenda = agendaFiles.map((a) => a.name);

    bodyObj.priceinr = parseInt(actualPriceINR);
    bodyObj.priceusd = parseInt(actualPriceUSD);
    bodyObj.priceafterinr = parseInt(priceAftINR);
    bodyObj.priceafterusd = parseInt(priceAftUSD);
    bodyObj.earlypriceinr = parseInt(earlyBirdINR);
    bodyObj.earlypriceusd = parseInt(earlyBirdUSD);
    if (sessionType === "online") {
      bodyObj.stype = 1;
    } else if (sessionType === "offline") {
      bodyObj.stype = 2;
    } else if (sessionType === "Hybrid") {
      bodyObj.stype = 3;
    }

    bodyObj.regdate = new Date(regStartDate).getTime();
    bodyObj.web_id = webID;
    bodyObj.weblink = webLink;
    bodyObj.web_pass = passcode;
    if (paid === "yes") {
      bodyObj.paid = "true";
    } else {
      bodyObj.paid = false;
    }

    if (perDis === "") {
      bodyObj.perdis = 0;
    } else {
      bodyObj.perdis = perDis;
    }

    bodyObj.earlydate = new Date(earlyDate).getTime();
    bodyObj.paylater = payLater;
    bodyObj.pay_within = new Date(payWithin).getTime();
    bodyObj.sdesc = des;
    bodyObj.lobj = object;
    bodyObj.taudi = target;
    bodyObj.location_value = locationValue;
    bodyObj.atype = 0;
    bodyObj.limit = slots;
    bodyObj.sn = name.replaceAll("'", "''");
    bodyObj.mt_id = 1;
    bodyObj.schema = Constants.SCHEMA_NAME;
    bodyObj.note = note;
    bodyObj.date_list = JSON.stringify(
      dateList.filter((item) => item.date !== "")
    );

    const bodyParam = {
      body: bodyObj,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.EDIT_SESSION,
        bodyParam
      );

      if (
        response ===
        'Database error: duplicate key value violates unique constraint "session_pkey"'
      ) {
        setSpin(false);
        swal({
          title: "Oooops!",
          text: "Course ID cannot be duplicated",
          icon: "warning",
          dangerMode: true,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      } else {
        setSpin(false);
        swal({
          title: "Success!",
          text: "Live Session Added successfully",
          icon: "success",
          dangerMode: false,
          closeOnClickOutside: false,
        }).then((willDelete) => {
          if (willDelete) {
            setSpin(false);
            handleAddLiveClose();
            getSessionList();
          }
        });
      }
    } catch (error) {
      swal({
        title: "Success!",
        text: "Live Session Added successfully",
        icon: "success",
        dangerMode: false,
        closeOnClickOutside: false,
      }).then((willDelete) => {
        if (willDelete) {
          setSpin(false);
        }
      });
    }
  };

  const Input = styled("input")({
    display: "none",
  });

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const nextDay = (value) => {
    let date = new Date(value);
    date.setDate(date.getDate() + 1);
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = date.getFullYear();
    date = yyyy + "-" + mm + "-" + dd;
    return date;
  };
  const addDateList = () => {
    setDateList((prevDateList) => {
      const date = nextDay(prevDateList[dateList.length - 1].date);
      return [
        ...prevDateList,
        {
          id: generate(),
          date: "",
          sTime: "",
          eTime: "",
          min: date,
        },
      ];
    });
  };
  const onChangeHandlerSessionDate = (e, idx) => {
    const { value } = e.target;
    let data = [...dateList];
    const combineStartTime = moment(value + " " + data[idx].sTime).format();
    const combineEndTime = moment(value + " " + data[idx].eTime).format();

    data[idx].combineStartTime = combineStartTime;
    data[idx].combineEndTime = combineEndTime;
    data[idx].date = value;

    setDateList(data);
  };
  const onChangeHandlerSessionStartTime = (e, idx) => {
    const { value } = e.target;
    let data = [...dateList];
    const combineStartTime = moment(data[idx].date + " " + value).format();

    if (new Date().getTime() > new Date(combineStartTime).getTime()) {
      setStartTimeError("Start Time should be in the future");
      data[idx].sTime = "";
      return;
    } else {
      setStartTimeError("");
    }
    data[idx].combineStartTime = combineStartTime;
    data[idx].sTime = value;

    setDateList(data);
  };
  const onChangeHandlerSessionEndTime = (e, idx) => {
    const { value } = e.target;
    let data = [...dateList];
    const combineEndTime = moment(data[idx].date + " " + value).format();

    if (
      new Date(combineEndTime).getTime() <=
      new Date(data[idx].combineStartTime).getTime()
    ) {
      setWrongDateError("End time should be greater then start time");
      data[idx].eTime = "";
      return;
    } else {
      setWrongDateError("");
    }
    data[idx].combineEndTime = combineEndTime;
    data[idx].eTime = value;
    setDateList(data);
  };

  const deleteDateFromList = (id) => {
    setDateList((prevDateList) => {
      return prevDateList.filter((item) => item.id !== id);
    });
  };

  return (
    <div className={Add.maincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Add.closeholder}>
        <h3 className={Add.heading}>Add a Live Session</h3>
        <Close className={Add.closeicon} onClick={handleAddLiveClose} />
      </div>

      <div className={Add.formfieldsholder}>
        <div className={Add.leftside}>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="name" className={Add.label} required>
                Copy Session Details from:
              </label>
            </div>
            <div className={Add.inputholder}>
              <select
                name="copy_details"
                id="copy_details"
                className={Add.inputfield}
                onChange={handleCopySession}
                defaultValue=""
              >
                <option value="" disabled>
                  Select a Session
                </option>
                {sessionList?.map((item, index) => (
                  <option key={item.sid} value={item.sid}>
                    {item.sn}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {nameError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="name" className={Add.label} required>
                Session Title
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                id="name"
                className={Add.inputfield}
                onChange={onChangeHandlerName}
                value={name}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {courseIdError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="courseid" className={Add.label} required>
                Session Id
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="text"
                id="courseid"
                className={Add.inputfield}
                maxLength="25"
                onChange={(e) => setCourseID(e.target.value)}
                value={courseID}
              ></input>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {imageError}
          </div>

          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "30%",
              fontSize: "10px",
            }}
          >
            {imageExtentionError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="image" className={Add.label} required>
                Image<span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <div className={Add.fileupload}>
                <label htmlFor="session-image" className={Add.labelnot}>
                  <Input
                    accept="image/*"
                    id="session-image"
                    type="file"
                    onChange={(e) => onChangeHandlerImage(e)}
                  />
                  <span className={Add.add}>Upload</span>
                  <h5
                    style={{ fontSize: "10px", color: "grey" }}
                    className={Add.fileEllipsis}
                  >
                    {image !== null && image.file !== undefined
                      ? image.file.name.substring(0, 15) + "..."
                      : ""}
                  </h5>
                </label>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {sessionStartTimeError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {startTimeError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {wrongStartDateError}
          </div>
          <div className={Add.inputblock}>
            <div className={`${Add.labelholder} ${Add.labelholderBT}`}>
              <label for="sessiondate" className={Add.label} required>
                Session date & time
              </label>
              <button
                disabled={
                  dateList[0].date !== "" &&
                  dateList[0].sTime !== "" &&
                  dateList[0].eTime !== ""
                    ? false
                    : true
                }
                onClick={addDateList}
              >
                Add
              </button>
            </div>
            {dateList?.map((item, index) => {
              return (
                <div className={Add.inputholder} key={item.id}>
                  <input
                    type="date"
                    id={`sessiondate-${generate()}`}
                    name="sessiondate"
                    onChange={(e) => onChangeHandlerSessionDate(e, index)}
                    value={item.date}
                    min={item.min}
                  />
                  <input
                    type="time"
                    id={`sTime-${generate()}`}
                    name="sTime"
                    disabled={item.date !== "" ? false : true}
                    onChange={(e) => onChangeHandlerSessionStartTime(e, index)}
                    value={item.sTime}
                  />
                  <input
                    type="time"
                    id={`eTime-${generate()}`}
                    name="eTime"
                    disabled={
                      item.date !== "" && item.sTime !== "" ? false : true
                    }
                    onChange={(e) => onChangeHandlerSessionEndTime(e, index)}
                    value={item.eTime}
                  />
                  {index !== 0 && (
                    <button onClick={() => deleteDateFromList(item.id)}>
                      Clear
                    </button>
                  )}
                </div>
              );
            })}
          </div>
          {/* <div
            style={{
              color: "#cc0000",
              textAlign: "start",
            
              fontSize: "10px",
            }}
          >
            {sessionEndTimeError}
          </div> */}
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {wrongDateError}
          </div>

          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {speakerTypeError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="speakertype" className={Add.label} required>
                Speaker Type
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <select
                id="speakertype"
                className={Add.inputfield}
                onChange={onChangeHandlerSpeakerType}
                value={speakerType}
              >
                <option disabled selected={defaultSpeaker} value="">
                  Select Speaker Type
                </option>
                <option value="Speaker">Speaker</option>
                <option value="Approved Instructor">Approved Instructor</option>
              </select>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {speakerError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="speaker" className={Add.label} required>
                Speaker
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.inputholder}>
              <select
                id="speaker"
                className={Add.inputfield}
                onChange={onChangeHandlerSpeaker}
                // value={speaker.name}
              >
                <option disabled selected={defaultSpeaker}>
                  Select Speaker
                </option>
                {speakerList.map((speaker, i) => {
                  return <option value={i}>{speaker.sp_name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className={Add.speakerlists}>
            {speakerList
              .filter((value) => speakerArray.includes(value.sp_id.toString()))
              .map(({ sp_id, sp_name }, id) => (
                <div className={Add.speakertag} key={id}>
                  <h4 className={Add.speakername}>{sp_name}</h4>
                  <Close
                    className={Add.cancelicon}
                    onClick={() => removeSpeaker(sp_id)}
                  />
                </div>
              ))}
          </div>

          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {slidesError}
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "40%",
              fontSize: "10px",
            }}
          >
            {docExtentionError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="summary" className={Add.label} required>
                Summary slides
              </label>
            </div>
            <div className={Add.holder}>
              <label htmlFor="sessionSlides">
                <Input
                  accept=".pdf,video/*,image/*"
                  id="sessionSlides"
                  type="file"
                  onChange={(e) => onChangeHandlerSlides(e)}
                />
                <span className={Add.add}>Upload</span>
                <h5 className={Add.fileEllipsis}>
                  <div>
                    {Array.isArray(certificateFile) &&
                      certificateFile.length > 0 &&
                      certificateFile.map((cfile) => {
                        return (
                          <div>
                            <h3 className={Add.speakername}>{cfile.name}</h3>
                          </div>
                        );
                      })}
                  </div>
                </h5>
              </label>
            </div>
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="agenda" className={Add.label} required>
                Agenda
              </label>
            </div>
            <div className={Add.holder}>
              <label htmlFor="agenda">
                <Input
                  accept=".pdf"
                  id="agenda"
                  type="file"
                  onChange={(e) => onChangeHandlerAgenda(e)}
                />
                <span className={Add.add}>Upload</span>
                <h5 className={Add.fileEllipsis}>
                  {agendaFiles?.map((agenda) => (
                    <div>
                      <h3 className={Add.speakername}>{agenda.name}</h3>
                    </div>
                  ))}
                </h5>
              </label>
            </div>
          </div>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              fontSize: "10px",
            }}
          >
            {sessionTypeError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="coursetype" className={Add.label} required>
                Session Type
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.checkholder}>
              <div>
                <input
                  type="radio"
                  value="online"
                  name="sessiontype"
                  className={Add.radioinput}
                  checked={sessionTypeCheck}
                  onChange={(e) => {
                    handleOnlineChange(e);
                  }}
                ></input>
                <span className={Add.span}>Live Web</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="offline"
                  name="sessiontype"
                  className={Add.radioinput}
                  checked={radioOffilne}
                  onChange={(e) => {
                    handleOfflineChange(e);
                  }}
                ></input>
                <span className={Add.span}>Classroom</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="Hybrid"
                  name="sessiontype"
                  className={Add.radioinput}
                  checked={radioHybrid}
                  onChange={(e) => {
                    handleHybridChange(e);
                  }}
                ></input>
                <span className={Add.span}>Hybrid</span>
              </div>
            </div>
          </div>
          {location === true ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  fontSize: "10px",
                }}
              >
                {locationError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="location" className={Add.label} required>
                    Location
                    <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <textarea
                    rows={4}
                    id="location"
                    className={Add.textfield}
                    onChange={(e) => onChangeHandleLocation(e)}
                    value={locationValue}
                  ></textarea>
                </div>
              </div>
            </>
          ) : null}
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "35%",
              fontSize: "10px",
            }}
          >
            {regStartDateError}
          </div>
          {/* <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="registrationdate" className={Add.label} required>
                Registration start date
              </label>
            </div>
            <div className={Add.inputholder}>
              <input
                type="date"
                id="registrationdate"
                className={Add.inputfield}
                onChange={onChangeHandlerRegStartDate}
                value={regStartDate}
                min={disablePastDate()}
                disabled={
                  dateList[0].date !== "" &&
                  dateList[0].sTime !== "" &&
                  dateList[0].eTime !== ""
                    ? false
                    : true
                }
              ></input>
            </div>
          </div> */}
          {offline === false ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {webLinkError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {webLinkFormatError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="webinarlink" className={Add.label}>
                    Webinar Link
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="webinarlink"
                    className={Add.inputfield}
                    onChange={onChangeHandlerWebLink}
                    value={webLink}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="webinarid" className={Add.label}>
                    Webinar ID
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="webinarid"
                    className={Add.inputfield}
                    onChange={onChangeHandlerWebID}
                    value={webID}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="passcode" className={Add.label}>
                    Passcode
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="passcode"
                    className={Add.inputfield}
                    onChange={onChangeHandlerPasscode}
                    value={passcode}
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="passcode" className={Add.label}>
                    Note
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="text"
                    id="note"
                    className={Add.inputfield}
                    onChange={onChangeHandlerNote}
                    value={note}
                  ></input>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className={Add.rightside}>
          <div
            style={{
              color: "#cc0000",
              textAlign: "start",
              marginLeft: "50%",
              fontSize: "10px",
            }}
          >
            {paidError}
          </div>
          <div className={Add.inputblock}>
            <div className={Add.labelholder}>
              <label for="paidcourse" className={Add.label} required>
                Paid Session
                <span style={{ color: "red", marginTop: "-10px" }}>*</span>
              </label>
            </div>
            <div className={Add.checkholder}>
              <div>
                <input
                  type="radio"
                  value="yes"
                  name="paylater"
                  checked={topayment}
                  className={Add.radioinput}
                  onChange={(e) => {
                    setView(true);
                    setPaymentTypeCheck(false);
                    setSlotsFree(false);
                    setSlots(dumslots);
                    onChangeHandlerPaid(e);
                  }}
                ></input>
                <span className={Add.span}>Yes</span>
              </div>
              <div>
                <input
                  type="radio"
                  value="no"
                  name="paylater"
                  className={Add.radioinput}
                  checked={paymentTypeCheck}
                  onChange={(e) => {
                    setView(false);
                    setPaymentTypeCheck(true);
                    setSlotsFree(true);
                    setSlots(0);
                    onChangeHandlerPaid(e);
                  }}
                ></input>
                <span className={Add.span}>No</span>
              </div>
            </div>
          </div>
          {view === true ? (
            <>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {totalSlotsError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="totalslots" className={Add.label} required>
                    Total slots
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="totalslots"
                    className={Add.inputfield}
                    onChange={onChangeHandlerSlots}
                    value={slots}
                    min="0"
                  ></input>
                </div>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {actualPriceError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="price" className={Add.label}>
                    Actual Price in INR
                    <span style={{ color: "red", marginTop: "-10px" }}>*</span>
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="price"
                    className={Add.inputfield}
                    onChange={onChangeHandlerActualPriceINR}
                    value={actualPriceINR}
                    min="0"
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="price" className={Add.label}>
                    Actual Price in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="price"
                    className={Add.inputfield}
                    onChange={onChangeHandlerActualPriceUSD}
                    value={actualPriceUSD}
                    min="0"
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="discount" className={Add.label}>
                    Percentage discount
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="discount"
                    className={Add.inputfield}
                    onChange={onChangeHandlerPerDis}
                    value={perDis}
                    min="0"
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="afterdiscount" className={Add.label}>
                    Price after discount in INR
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="afterdiscount"
                    className={Add.inputfield}
                    value={priceAftINR}
                    min="0"
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="afterdiscount" className={Add.label}>
                    Price after discount in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="afterdiscount"
                    className={Add.inputfield}
                    value={priceAftUSD}
                    min="0"
                  ></input>
                </div>
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="birdprice" className={Add.label}>
                    Early bird price in INR
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="birdprice"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyBirdINR}
                    value={earlyBirdINR}
                    min="0"
                  ></input>
                </div>
              </div>

              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="birdprice" className={Add.label}>
                    Early bird price in USD
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="number"
                    id="birdprice"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyBirdUSD}
                    value={earlyBirdUSD}
                    min="0"
                  ></input>
                </div>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {earlyError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {earlyDateError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="enddate" className={Add.label}>
                    Early bird price end date
                  </label>
                </div>
                <div className={Add.inputholder}>
                  <input
                    type="date"
                    id="enddate"
                    className={Add.inputfield}
                    onChange={onChangeHandlerEarlyDate}
                    value={earlyDate}
                  ></input>
                </div>
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {payLaterError}
              </div>
              <div
                style={{
                  color: "#cc0000",
                  textAlign: "start",
                  marginLeft: "50%",
                  fontSize: "10px",
                }}
              >
                {payLaterDateError}
              </div>
              <div className={Add.inputblock}>
                <div className={Add.labelholder}>
                  <label for="paylater" className={Add.label}>
                    Enable pay later
                  </label>
                </div>
                <div className={Add.paywithindate}>
                  <input
                    type="checkbox"
                    id="paylate"
                    checked={payLater}
                    onChange={(e) => onChangeHandlerPayLater(e)}
                    value={payLater}
                  ></input>

                  <div className={Add.paywithholder}>
                    <label for="paylater" className={Add.label}>
                      Pay within date
                    </label>
                    <input
                      type="date"
                      id="paylater"
                      className={Add.smallfield}
                      onChange={onChangeHandlerPayWithin}
                      value={payWithin}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className={Add.bottomfields}>
        <label for="description" required className={Add.label}>
          Description
        </label>
        <textarea
          id="description"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerDes}
          value={des}
        />
        <label for="learning" required className={Add.label}>
          Learning objectives
        </label>
        <textarea
          id="learning"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerObject}
          value={object}
        />
        <label for="audience" required className={Add.label}>
          Target audience
        </label>
        <textarea
          id="audience"
          className={Add.textarea}
          rows={3}
          onChange={onChangeHandlerTarget}
          value={target}
        />
      </div>

      <div className={Add.buttonholder}>
        <button className={Add.cancel} onClick={handleAddLiveClose}>
          Cancel
        </button>
        <button className={Add.add} type="submit" onClick={submitForm}>
          Add
        </button>
      </div>
    </div>
  );
};

export default SessionAdd;
