import React, { useState, useEffect, useRef } from "react";

import Table from "@mui/material/Table";
import { API } from "aws-amplify";
import config from "../../../config/aws-exports";
import { styled } from "@mui/system";
import swal from "sweetalert2";
import swal1 from "@sweetalert/with-react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import BackDrop from "@material-ui/core/Backdrop/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { Constants } from "../../../config/Constants";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";

// Local imports
import { ReactComponent as Close } from "../../../assets/icons/close_black_24dp.svg";
import CourseFeedback from "./CourseFeedback";
import Feedback from "./Feedback";
// Styles imports
import Ap from "./ApproveUsers.module.scss";
import Li from "./ListofUsers.module.scss";
import { TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    heigth: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: "#fff" },
}));

const header = {
  color: "white",
  fontSize: "16px",
  fontWeight: "400px",
};

export default function RegisteredUsers(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  let { handleRegClose, topicId, tenantoid, getTopicList, listForReg } = props;
  
  const [page, setPage] = useState(0);
  const [imageExtentionError, setImageExtentionError] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [invoiceimg, setInvoiceimg] = useState(null);
  const [invoiceFileName, setInvoiceFileName] = useState(null);
  const [certificateimg, setCertificateImg] = useState(null);
  const [certificateFileName, setCertificateFileName] = useState(null);
  const [registeredUsers, setRegisteredUsers] = useState([]);
  const [spin, setSpin] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [coursefeedbackOpen, setCourseFeedbackOpen] = useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [viewingState, setViewingState] = useState({});
  const [certificateOpen, setCertificateOpen] = useState(false);
  const [userDetailsReg, setUserDetailsReg] = useState({});
  const [viewingStatecertificate, setViewingStatecertificate] = useState({});
  const refselectedInvoiceFile = useRef();
  const refselectedCertificateFile = useRef();
  const [newArray, setNewArray] = useState([]);
  const [feedData, setFeedData] = useState({});
  const [coursefeedData, setCourseFeedData] = useState({});
  const [showButton, setShowButton] = useState(false);
  const [inputValue, setInputValue] = useState([]);
  const [actualAmount, setActualAmount] = useState(0);
  let userDetails = useSelector(authData);

  const Input = styled("input")({
    display: "none",
  });

  useEffect(() => {
    getRegisteredUsers();
  }, []);

  const handleViewClick = (item) => {
   
    setViewingState(item);
    setInvoiceOpen(true);
  };
  const handleViewClickCertificate = (item) => {
    setViewingStatecertificate(item);
    setCertificateOpen(true);
  };

  const handleFeedBackOpen = (item) => {
    setFeedbackOpen(true);
    setFeedData(item);
    console.log("itemfeed", item);
  };

  const handleFeedBackClose = () => {
    setFeedbackOpen(false);
    
  };

  const handleCourseFeedBackOpen = (item) => {
    setCourseFeedbackOpen(true);
    setCourseFeedData(item); 
  };

  const handleCourseFeedBackClose = () => {
    setCourseFeedbackOpen(false);
    setSpin(false);
  };

  const handleClickCertificateOpen = (item) => {
    setUserDetailsReg(item);
    setCertificateOpen(true);
  };

  async function getRegisteredUsers() {
    setSpin(true);
    const bodyParam = {
      body: {
        tid: topicId,
        type:"course",
        oid: config.aws_org_id,
        typeusers: "reg_users",
        tenantoid: tenantoid,
        tenant: null,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const reg = await API.post(
        config.aws_cloud_logic_custom_name,
        // "/getTopicRegisteredUsers",
        Constants.GET_TOPIC_REGISTERED_USERS,
        bodyParam
      );

      console.log("reg", reg);
      let registeredusers = [];
      /*  for (let i = 0; i < reg.body.length; i++) {
        if (reg.body[i].pstatus !== 10) {
          registeredusers.push(reg.body[i]);
        }
      } */
      setRegisteredUsers(reg.body);
      // CopyOldAttendence(reg.body);
      setSpin(false);
      //setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
      setSpin(false);
    }
  }

  const getExtension = (filename) => {
    let parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const isImage = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
      default:
        return false;
    }
  };

  const isDoc = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "pdf":
        return true;
      default:
        return false;
    }
  };

  const handleimage = (event, item) => {
    console.log("1", item.ur_id);
    if (!isDoc(event.target.files[0].name)) {
      swal1("Wrong file format! Accepted file format: .pdf");
      return;
    }
    setImageExtentionError("");
    setSpin(true);

    setInvoiceimg({ file: event.target.files[0] });
    refselectedInvoiceFile.current = event.target.files[0];
    console.log("image");
    handleimageUpload(event.target.files[0], item);
  };
  const handleimageRE = (event, item) => {
    console.log("RE", item);
    //console.log("REi", i);
    if (!isDoc(event.target.files[0].name)) {
      swal1("Wrong file format! Accepted file format: .pdf");
      return;
    }
    setImageExtentionError("");
    setSpin(true);

    setInvoiceimg({ file: event.target.files[0] });
    refselectedInvoiceFile.current = event.target.files[0];
    console.log("image");
    handleimageUpload(event.target.files[0], item);
  };

  function handleimageUpload(imageGot, item) {
    console.log("2", item.ur_id);
    if (imageGot == null) {
      return;
    }

    if (imageGot.size > 5242880) {
      swal1(
        "Oops!",
        "File size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (imageGot !== undefined) {
      let uniqueStamp = new Date().getTime();
      let invoiceID = uniqueStamp + ".pdf";
      setInvoiceFileName(uniqueStamp + ".pdf");

      var body = {
        type: "uploadInvoice",
        oid: config.aws_org_id,
        filetype: imageGot.type,
        filename: invoiceID,
      };

      console.log("bodyImg", body);
      uploadfile(body, imageGot, invoiceID, item);
    }
  }

  async function uploadfile(body, file, invoiceID, item) {
    console.log("3", item.ur_id);
    //setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        /* "/getpreSignedURL", */
        bodyParam
      );

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
          uploadInvoice(invoiceID, item);
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });

      //setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  async function uploadInvoice(invoiceID, item) {
    console.log("4", item.ur_id);
    //item.invoiceID = invoiceID;
    const bodyParam = {
      body: {
        tid: topicId,
        ur_id: item.ur_id,
        invoiceID: invoiceID,
        /* uData: item,
        eid: item.eid,
        tenant: item.tenant, */

        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const upload = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPLOAD_INVOICE,

        bodyParam
      );

      console.log("upload", upload);
      refselectedInvoiceFile.current = null;
      getRegisteredUsers();
      setSpin(false);
      swal1("Uploded Sucessfully");
      //setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      swal1("Uploded unsucessfull");
      console.error(error);
      setSpin(false);
    }
  }

  const handleimageCertificate = (event, item) => {
    setSpin(true);
    setCertificateImg({ file: event.target.files[0] });
    refselectedCertificateFile.current = event.target.files[0];
    console.log("image");
    handleimageUploadCertificate(event.target.files[0], item);
  };

  function handleimageUploadCertificate(imageGot, item) {
    if (imageGot == null) {
      return;
    }

    if (imageGot.size > 5242880) {
      swal1(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }
    if (imageGot !== undefined) {
      let uniqueStamp = new Date().getTime();
      let CertID = uniqueStamp + ".png";
      setCertificateFileName(uniqueStamp + ".png");

      var body = {
        type: "uploadCert",
        oid: config.aws_org_id,
        filetype: imageGot.type,
        filename: CertID,
      };

      console.log("bodyImg", body);
      uploadfileCertificate(body, imageGot, CertID, item);
    }
  }

  async function uploadfileCertificate(body, file, CertID, item) {
    //setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        /* "/getpreSignedURL", */
        bodyParam
      );

      axios
        .put(preSignedData, file, {
          headers: {
            "Content-Type": file.type,
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          console.log("file upload success");
          uploadCertificate(CertID, item);
        })

        .catch((error) => {
          console.error("There was an error!", error);
        });

      //setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  function CopyOldAttendence(registeredUsersGot) {
    let temp = [];
    if (registeredUsersGot !== null) {
      if (registeredUsersGot !== undefined && registeredUsersGot.length > 0) {
        for (let i = 0; i < registeredUsersGot.length; i++) {
          if (registeredUsersGot[i].attend === 1) {
            temp.push(registeredUsersGot[i].ur_id.toString());
          }
        }
      }
    }
    //console.log(sessionRowData.tenants.length);
    console.log("temp1", temp);
    setNewArray(temp);
  }

  const handleClick = (event, item) => {
    let tempArray = [...newArray];
    if (tempArray.includes(item)) {
      var index = tempArray.indexOf(item);
      tempArray.splice(index, 1);
    } else {
      tempArray.push(item);
    }
    console.log("aten", tempArray);
    setNewArray(tempArray);
  };

  async function uploadCertificate(CertID, item) {
    item.certid = CertID;
    const bodyParam = {
      body: {
        tid: topicId,
        uData: item,

        eid: item.eid,
        tenant: item.tenant,

        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const upload = await API.post(
        config.aws_cloud_logic_custom_name,
        "/uploadCert",

        bodyParam
      );

      console.log("upload", upload);
      getRegisteredUsers();
      setSpin(false);
      swal
        .fire({
          title: "Success",
          text: "Uploaded successfully",
          icon: "success",
          dangerMode: false,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      //setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      swal
        .fire({
          title: "Oooops!",
          text: "Soomthing wrong!",
          icon: "warning",
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setSpin(false);
          }
        });
      console.error(error);
      setSpin(false);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // function markAttendance() {
  //   let unmark = [];
  //   for (let i = 0; i < registeredUsers.length; i++) {
  //     if (!newArray.includes(registeredUsers[i].ur_id.toString())) {
  //       unmark.push(registeredUsers[i].ur_id.toString());
  //     }
  //   }
  //   console.log("unmark", unmark);
  //   console.log("mark", newArray);
  //   // updateAttendence(unmark);
  // }
  const handleSave = async (i) => {
    let newArr = [];
    let upd_value = [...registeredUsers];
    upd_value?.forEach((value) => {
      if (value.actual_amount) {
        let obj = {};
        obj["amount"] = parseFloat(value?.actual_amount);
        obj["ur_id"] = value?.ur_id;
        newArr.push(obj);
      }
    });
    console.log(newArr);

    const bodyParam = {
      body: {
        type: "course",
        id: topicId,
        userJson: newArr,
        schema: "uspsm",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      console.log("==", bodyParam);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_ACTUAL_AMOUNT,
        bodyParam
      );
      setActualAmount(response.body);
      // setSpin(true);
      swal1("Success", "Amount Updated Successfully", "success");
      getTopicList();
    } catch (error) {
      console.error(error);
    }
    setInputValue("");
  };

  return (
    <div className={Ap.maincontainer}>
      <BackDrop sx={{ color: "#fff" }} open={spin} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </BackDrop>
      <div className={Ap.topbar}>
        <h3 className={Ap.header}>Registered Participants</h3>
        {showButton ? (
          <div style={{ marginLeft: "75%" }}>
            <button className={Li.savebutton} onClick={handleSave}>
              Save
            </button>
          </div>
        ) : null}
        <Close className={Ap.closeicon} onClick={() => handleRegClose()} />
      </div>
      <Dialog
        open={coursefeedbackOpen}
        aria-labelledby="responsive-dialog-title"
        scroll="paper"
        maxWidth="lg"
      >
        <CourseFeedback
          handleCourseFeedBackClose={handleCourseFeedBackClose}
          coursefeedData={coursefeedData}
          setSpin={setSpin} 
          spin={spin}
        />
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={feedbackOpen}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={feedbackOpen}>
          <Feedback setSpin={setSpin} spin={spin} />
        </Fade>
      </Modal>
      <Table>
        <TableHead sx={{ background: "#285780" }}>
          <TableRow>
            <TableCell style={header}>First Name</TableCell>
            <TableCell style={header}>Last Name</TableCell>
            <TableCell style={header}>Email</TableCell>
            {userDetails.role === "Manager" ? null : tenantoid === false ? (
              <TableCell style={header}>Invoice</TableCell>
            ) : null}
            {tenantoid === false ? (
              <TableCell style={header}>Payment Method</TableCell>
            ) : null}
            <TableCell style={header}>Actual Amount</TableCell>
            <TableCell style={header}>Feedback</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {(rowsPerPage > 0
            ? registeredUsers.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : registeredUsers
          ).map((item, i) => {
            return (
              <TableRow>
                <TableCell>
                  {item.first_name}
                  {/* {item.ur_id} */}
                </TableCell>
                <TableCell>{item.last_name}</TableCell>
                <TableCell>{item.emailid}</TableCell>
                {tenantoid === true ? (
                  <TableCell>
                    {item.fbstatus === false ? (
                      <h3 style={{ fontSize: "14px", fontWeight: "300" }}>
                        No Feedback
                      </h3>
                    ) : (
                      <button
                        onClick={() => handleFeedBackOpen(item)}
                        className={Ap.feedbackbutton}
                      >
                        View Feedback
                      </button>
                    )}
                  </TableCell>
                ) : null}

                {userDetails.role === "Manager" ? null : tenantoid === false ? (
                  listForReg.paid === "false" ? null : item?.inid ===
                      undefined || item?.inid === null 
                    ? 
                    (<TableCell>
                      <div>
                        <span>Upload PDF</span>
                        <input
                          accept=".pdf"
                          multiple
                          type="file"
                          onChange={(e) => handleimage(e, item)}
                        />
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <div>
                        <div>
                          <button
                            className={Li.addbutton}
                            onClick={() => handleViewClick(item)}
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            View
                          </button>
                        </div>
                        <div>
                          <span>To Reupload </span>
                          <input
                            accept=".pdf"
                            multiple
                            type="file"
                            onChange={(e) => handleimageRE(e, item)}
                          />
                        </div>
                      </div>
                    </TableCell>
                  )
                ) : null}

                {tenantoid === false ? (
                  <TableCell>
                    {item.pstatus === 0 ? "NEFT" : null}
                    {item.pstatus === 1 ? "RazorPay" : null}
                    {item.pstatus === 2 ? "Pay Later" : null}{" "}
                    {item.pstatus === 10 ? "Payment in process" : null}
                  </TableCell>
                ) : null}
                <TableCell>
                  <TextField
                    type="number"
                    value={
                      item?.actual_amount
                        ?.replace(/[^0-9.]/g, "")
                        ?.replace(/(\..*)\./g, "$1") || ""
                    }
                    onChange={(e) => {
                      let copyRegisteredUsers = [...registeredUsers];
                      copyRegisteredUsers[i].actual_amount = e.target.value;
                      copyRegisteredUsers[i].updated_amount = true;
                      setRegisteredUsers(copyRegisteredUsers);
                      setShowButton(true);
                    }}
                  />
                </TableCell>
                {/* {tenantoid === false ? (
                  <TableCell>{item.assign}</TableCell>
                ) : null} */}

                {/* {userDetails.role === "Manager" ? null : tenantoid === false ? (
                  <TableCell>
                    {" "}
                    
                    <Checkbox
                      checked={newArray.includes(item.ur_id.toString())}
                      onChange={(e) => handleClick(e, item.ur_id.toString())}
                    />
                  </TableCell>
                ) : null} */}

                {/* {item.certid === undefined ? (
                  <TableCell>
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => handleimageCertificate(e, item)}
                      />
                      <Button
                        sx={{
                          color: "#ff6f4d",
                          border: "2px solid #ff6f4d",
                          "&:hover": {
                            border: "2px solid #ff6f4d",
                          },
                        }}
                        className={Ap.upload}
                        //variant="outlined"
                        component="span"
                      >
                        Upload Certificate
                      </Button>
                      <h5>
                        {" "}
                        {certificateimg !== null &&
                        certificateimg.file !== undefined
                          ? certificateimg.file.name
                          : ""}
                      </h5>
                    </label>
                  </TableCell>
                ) : (
                  <Button
                    sx={{
                      color: "#ff6f4d",
                      border: "2px solid #ff6f4d",
                      "&:hover": {
                        border: "2px solid #ff6f4d",
                      },
                    }}
                    style={{ height: "30px", marginTop: "10px" }}
                    //className={Ap.upload}
                    onClick={() => handleViewClickCertificate(item)}
                    //variant="outlined"
                    //component="span"
                  >
                    View
                  </Button>
                )} */}
                {/* {item.aprstatus === 1 &&
                item.attend === 1 &&
                item.fbstatus === true &&
                item.certid !== null ? (
                  <TableCell>
                    <button
                      className={Li.addbutton}
                      onClick={() => {
                        handleClickCertificateOpen(item);
                      }}
                    >
                      View certificate
                    </button>
                  </TableCell>
                ) : (
                  <TableCell>
                    <p>No certificates yet</p>
                  </TableCell>
                )} */}
                <TableCell>
                    {item.feedback === false ? (
                      <h3 style={{ fontSize: "14px", fontWeight: "300" }}>
                        No Feedback
                      </h3>
                    ) : (
                      <button
                        onClick={() => handleCourseFeedBackOpen(item)}
                        className={Ap.feedbackbutton}
                      >
                        View Feedback
                      </button>
                    )}
                  </TableCell>
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={tenantoid === true ? 5 : 7}
              count={registeredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>

      <Dialog
        fullScreen={fullScreen}
        open={invoiceOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <div style={{ padding: "20px" }}>
          <Close
            className={Ap.closeicon}
            style={{ float: "right" }}
            onClick={() => setInvoiceOpen(false)}
          />
          <h5>Invoice - {viewingState.first_name} </h5>
          <iframe
            style={{
              width: "750px",
              height: "800px",
              borderRadius: "10px",
            }}
            src={`https://${
              config.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${
              viewingState.inid
            }`}
            height="100%"
            width="100%"
            title="pdfView"
          />
          {/* <img
            style={{
              width: "650px",
              height: "800px",
              borderRadius: "10px",
            }}
            src={`https://${
              config.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/documents/invoices/${
              viewingState.inid
            }`}
            alt=""
          ></img> */}
        </div>
      </Dialog>
      {/*  <Dialog
        fullScreen={fullScreen}
        open={certificateOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      >
        <div style={{ padding: "20px" }}>
          <Close
            className={Ap.closeicon}
            style={{ float: "right" }}
            onClick={() => setCertificateOpen(false)}
          />
          <h5>Invoice - {viewingStatecertificate.email}</h5>
          <img
            style={{
              width: "650px",
              height: "800px",
              borderRadius: "10px",
            }}
            src={`https://${
              config.DOMAIN
            }/${config.aws_org_id.toLowerCase()}-resources/documents/certificates/${
              viewingStatecertificate.certid
            }`}
            alt=""
          ></img>
        </div>
      </Dialog> */}
      {/*  <Dialog
        fullScreen={fullScreen}
        open={certificateOpen}
        aria-labelledby="responsive-dialog-title-2"
        maxWidth="md"
      > */}
      {/* <div style={{ padding: "20px" }}>
        <CertificatePopUp
          open={certificateOpen}
          setOpen={setCertificateOpen}
          details={userDetailsReg}
          // sData}=     />
      </div> */}
      {/*  </Dialog> */}
    </div>
  );
}
