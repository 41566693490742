export const color = {
  primary: "#275780",
  secondary: "#ff6f4d",
};

export const Theme = {
  color: {
    primary: "#275780",
    secondary: "#ff6f4d",
    secondaryLight: "rgba(40, 87, 129, 0.2)",
    text: "#000",
  },
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
};

const uspdev = {
  //aws_app_analytics: 'disable',
  main_color_1: "#275780",
  main_color_2: "#F18121",
  platform_main_theme: "linear-gradient(#275780, #275780)",
  button_color_web: "",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "qlwvf0kwt4",
      name: "USP-AUTHORING",
      description: "",
      endpoint:
        "https://qlwvf0kwt4.execute-api.us-east-1.amazonaws.com/USP-dev",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "qlwvf0kwt4",
      name: "USP-AUTHORING",
      description: "",
      endpoint:
        "https://qlwvf0kwt4.execute-api.us-east-1.amazonaws.com/USP-dev",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "USP-AUTHORING",
  aws_cloud_logic_custom_name_E: "USP-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:46d24045-4731-4036-813e-399b36a8b1e0",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-dev-usp",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "d2n6qshoat8nng.cloudfront.net",
  DOMAIN: "d2n6qshoat8nng.cloudfront.net",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_resource_name_prefix: "usp-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_z2pRocCYo",
  aws_user_pools_web_client_id: "1v8e76hrp2i8rj51ib8pau9s60",
  aws_cloud_logic_custom_endpoint:
    "https://qlwvf0kwt4.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "USP",
  aws_org_id_E: "USP",
  aws_user_pools_id_E: "us-east-1_z2pRocCYo",
  aws_user_pools_web_client_id_E: "1pd107l82ghrr0a8ptmgi9njlg",
  aws_content_delivery_cloudfront_domain_E: "d2n6qshoat8nng.cloudfront.net",
  aws_cloudfront_url: "d2n6qshoat8nng.cloudfront.net",
  aws_cognito_identity_pool_id_E:
    "us-east-1:55a63979-c796-4fc3-8fc8-140fae5debf6",
  aws_cloud_logic_custom_endpoint_E:
    "https://qlwvf0kwt4.execute-api.us-east-1.amazonaws.com/USP-dev/",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "qlwvf0kwt4",
      name: "USP-PLATFORM",
      description: "",
      endpoint:
        "https://qlwvf0kwt4.execute-api.us-east-1.amazonaws.com/USP-dev/",
      region: "us-east-1",
    },
  ],
};
const usppreprod = {
  //aws_app_analytics: 'disable',
  main_color_1: "#275780",
  main_color_2: "#F18121",
  platform_main_theme: "linear-gradient(#275780, #275780)",
  button_color_web: "",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "5z3gdcxzsi",
      name: "USP-AUTHORING",
      description: "",
      endpoint:
        "https://5z3gdcxzsi.execute-api.us-east-1.amazonaws.com/usp-prod",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_E: [
    {
      id: "qlwvf0kwt4",
      name: "USP-AUTHORING",
      description: "",
      endpoint:
        "https://qlwvf0kwt4.execute-api.us-east-1.amazonaws.com/USP-dev",
      region: "us-east-1",
    },
  ],
  aws_cloud_logic_custom_name: "USP-AUTHORING",
  aws_cloud_logic_custom_name_E: "USP-AUTHORING",
  aws_cognito_identity_pool_id:
    "us-east-1:46d24045-4731-4036-813e-399b36a8b1e0",
  aws_cognito_region: "us-east-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-p2-usp",
  aws_content_delivery_bucket_region: "us-east-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "www.p2.usp-admin.enhanzed.com",
  DOMAIN: "www.p2.usp-admin.enhanzed.com",
  aws_facebook_app_id: "281569732265049",
  aws_facebook_app_permissions: "public_profile",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "us-east-1",
  aws_resource_name_prefix: "usp-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "us-east-1_CRFVTZGoS",
  aws_user_pools_web_client_id: "5fbu4v99dqu2e6rja8pdn10s98",
  aws_cloud_logic_custom_endpoint:
    "https://5z3gdcxzsi.execute-api.us-east-1.amazonaws.com/",
  aws_org_id: "USP",
  aws_org_id_E: "USP",
  aws_user_pools_id_E: "us-east-1_CRFVTZGoS",
  aws_user_pools_web_client_id_E: "5fbu4v99dqu2e6rja8pdn10s98",
  aws_content_delivery_cloudfront_domain_E: "www.p2.usp-admin.enhanzed.com",
  aws_cloudfront_url: "www.p2.usp-admin.enhanzed.com",
  aws_cognito_identity_pool_id_E:
    "us-east-1:55a63979-c796-4fc3-8fc8-140fae5debf6",
  aws_cloud_logic_custom_endpoint_E:
    "https://5z3gdcxzsi.execute-api.us-east-1.amazonaws.com/usp-prod/",
  aws_ctype: 0,
  aws_ctype_E: 0,
  aws_cloud_logic_custom_E1: [
    {
      id: "5z3gdcxzsi",
      name: "USP-PLATFORM",
      description: "",
      endpoint:
        "https://5z3gdcxzsi.execute-api.us-east-1.amazonaws.com/usp-prod/",
      region: "us-east-1",
    },
  ],
};
const uspprod = {
  //aws_app_analytics: 'disable',
  main_color_1: "#275780",
  main_color_2: "#F18121",
  platform_main_theme: "linear-gradient(#275780, #275780)",
  button_color_web: "",
  aws_auth_facebook: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "utduskj2p1",
      name: "USP-AUTHORING",
      description: "",
      endpoint:
        "https://utduskj2p1.execute-api.ap-south-1.amazonaws.com/USP-prod",
      region: "ap-south-1",
    },
  ],
  aws_cloud_logic_custom_name: "USP-AUTHORING",
  // aws_cognito_identity_pool_id:
  //   "ap-south-1:46d24045-4731-4036-813e-399b36a8b1e0",
  aws_cognito_region: "ap-south-1",
  aws_content_delivery: "enable",
  aws_content_delivery_bucket: "ed-prod-usp",
  aws_content_delivery_bucket_region: "ap-south-1",
  aws_content_delivery_cloudfront: "enable",
  aws_content_delivery_cloudfront_domain: "dbotrgenun6h9.cloudfront.net",
  DOMAIN: "dbotrgenun6h9.cloudfront.net",
  aws_mandatory_sign_in: "enable",
  aws_project_region: "ap-south-1",
  aws_resource_name_prefix: "usp-resources",
  aws_sign_in_enabled: "enable",
  aws_user_pools: "enable",
  aws_user_pools_id: "ap-south-1_5gB549mVF",
  aws_user_pools_web_client_id: "6h8eqkb1hnk37s16oi15tq6nji",
  aws_cloud_logic_custom_endpoint:
    "https://utduskj2p1.execute-api.ap-south-1.amazonaws.com/USP-prod",
  aws_org_id: "USP",
  aws_cloudfront_url: "www.usp-admin.enhanzed.com",
  aws_ctype: 0,
};

export default usppreprod;
