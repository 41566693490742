import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import Paper from "@mui/material/Paper";
import Cviewstyle from "./CourseCreation/courseCreation.module.scss";
import viewTopicStyle from "./viewTopic.module.scss";
import conStyle from "./contentAndColleteral.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ViewTopicContent from "./viewTopicContent";
import config from "../../../config/aws-exports";
import { API } from "aws-amplify";
import { Constants } from "../../../config/Constants";
import AddPrefrences from "./CourseCreation/addPrefrences";
import addimg from "../../../assets/images/addimg.png";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import black from "../../../assets/images/black.png";
import addpluse from "../../../assets/images/add.png";
import video from "../../../assets/objects/video.png";
import audio from "../../../assets/objects/audio.png";
import pdf from "../../../assets/objects/pdf.png";
import inter from "../../../assets/objects/inter.png";
import quiz from "../../../assets/objects/quiz.png";
import youtube from "../../../assets/objects/youtube.png";
import vimeo from "../../../assets/objects/vimeo.png";
import html from "../../../assets/objects/html.png";
import scorm from "../../../assets/objects/scorm.png";
import { useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Input,
  Modal,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import swal from "sweetalert";
import produce from "immer";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { Skeleton } from "@mui/material";
import ObjectPopUp from "./CourseCreation/AddObjects";
import CoursePlayer from "./coursePlayer";
import DialogBox from "./Dialogbox";
import { useQueryParams } from "../../Hooks/useQueryParams";
import Cookies from "js-cookie";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: "20px",
    height: "fit-content",
    overflowY: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
  },
  ".css-13xfq8m-MuiTabPanel-root": {
    padding: 0,
  },
}));

export default function ViewTopic() {
  const [value, setValue] = useState("2");
  const history = useHistory();
  const query = useQueryParams();
  const classes = useStyles();
  const [objectData, setObjectData] = useState({});
  const [oIndex, setOIndex] = useState(0);
  const [curObject, setcurObject] = useState({});
  const curObRef = useRef({});
  const [nuggets, setNuggets] = useState([]);
  const [cNugget, setcNugget] = useState({});
  const [topImg, setTopImg] = useState(false);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [objOpen, setObjOpen] = useState(false);
  const [topicData, setTopicData] = useState({});
  console.log(topicData);
  const [nopen, setNopen] = useState(false);
  const [overview, setOverview] = useState("");
  const refselectedCourseFile = useRef();
  const refnuggetValue = useRef("");
  const [folder, setFolder] = useState(Math.round(new Date().getTime() / 1000));
  const [istLoading, setIstLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [qisLoading, setQIsLoading] = useState(false);
  const [summative, setSummative] = useState(false);
  const [editObj, setEditObj] = useState({
    editbtn: true,
    meditmode: false,
    oeditmode: false,
    nuggetchanges: false,
    topicimgchanges: false,
  });
  const [errors, setErrors] = useState({ nval: false });
  const [selectedObj, setSelectedObj] = useState({});
  const [editButton, setEditButton] = useState(false);
  const scrollRef = useRef();
  const accRef = useRef();
  const [topics, setTopics] = useState([]);
  const [deleteData, setDeleteData] = useState({
    nuggets: [],
    objects: [],
  });
  let userDetails = useSelector(authData);
  const [priceininr, setPriceininr] = useState("");
  const [priceinusd, setPriceinusd] = useState("");
  const [earlyPriceInr, setEarlyPriceInr] = useState("");
  const [earlyPriceUsd, setEarlyPriceUsd] = useState("");
  const [earlyDate, setEarlyDate] = useState("");
  const [courseType, setCourseType] = useState("free");
  const [perDis, setPerDis] = useState("");
  const [priceafterDisInr, setPriceafterDisInr] = useState("");
  const [priceafterDisUsd, setPriceafterDisUsd] = useState("");
  const [speakerType, setSpeakerType] = useState("");
  const [sepaker, setSpeaker] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  const [speakerArray, setSpeakerArray] = useState([]);
  const dispatch = useDispatch();

  const handleAcChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    // scrollRef.current.scrollTo(
    //   0,
    //   document.getElementById("acc" + panel).offsetTop - 100
    // );
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getTopics();
    breadcrumb();
    getTopicList();
    getSpeaker();
    // getTopicsDetails();

    return () => {};
  }, []);

  async function getSpeaker() {
    setIstLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        action: "get",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const speakerData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SPEAKER_LIST,
        bodyParam
      );
      //console.log("speaker", speakerData);

      setSpeakerList(speakerData.body);

      setIstLoading(false);
    } catch (error) {
      //console.log("getUserError", error);
    }
  }

  async function getTopicList() {
    let sdata = { ...userDetails };
    setIsLoading(true);
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        tid: sdata?.categorydata?.id,
        admin: true,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      //console.log(JSON.stringify(bodyParam.body));
      const list = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_CATEGORY_DATA,
        bodyParam
      );

      const rows = list.courses.filter((item) => {
        if (item.tid !== userDetails.tid) {
          return item;
        }
      });

      setTopics(rows);
    } catch (error) {
      console.error(error);
    }
  }
  async function getTopics() {
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        vtid: userDetails.tid,
        schema: Constants.SCHEMA_NAME,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      //console.log(JSON.stringify(bodyParam.body));
      const topic2 = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_TOPIC,
        bodyParam
      );

      var topic3 = topic2.rows[0];
      //console.log(topic3);
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", topic2.Policy);
      Cookies.set("CloudFront-Signature", topic2.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", topic2.KeyPairId);
      setTopicData(topic3);
      setOverview(topic3.tdesc);

      setNuggets(topic3.nuggets);
      setPriceininr(topic3.priceinr);
      setPriceinusd(topic3.priceusd);
      setEarlyPriceInr(topic3.earlypriceinr);
      setEarlyPriceUsd(topic3.earlypriceusd);
      setEarlyDate(topic3.earlydate);
      setCourseType(topic3.coursetype);
      setPerDis(topic3.perdis);
      setPriceafterDisInr(topic3.priceafterdisinr);
      setPriceafterDisUsd(topic3.priceafterdisusd);
      setSpeaker(topic3.speakerarray);
      setSpeakerArray(topic3.speakerarray);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const changeHandler = (event) => {
    editObj.topicimgchanges = true;
    setTopImg(true);

    if (event.target.files == null) {
      return;
    }
    if (event.target.files[0].size > 5242880) {
      swal(
        "Oops!",
        "Image size is too large. File size cannot be greater than 5mb.",
        "error"
      );
      return;
    }

    if (event.target.files[0] !== undefined) {
      refselectedCourseFile.current = event.target.files[0];
      //setSelectedCourseFile((currentState) => (event.target.files[0]));
      var body = {
        filetype: "image/png",
        filename: "topic.png",
        folder: folder,
      };

      uploadfile(body);
    }
  };

  async function uploadfile(body) {
    setIstLoading(true);
    const bodyParam = {
      body,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const preSignedData = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_PRESIGNED_URL,
        //"/getpreSignedURL",
        bodyParam
      );

      axios
        .put(preSignedData, refselectedCourseFile.current, {
          headers: {
            "Content-Type": "image/png",
            "Cache-Control": "no-cache no-store must-revalidate",
          },
        })
        .then((response) => {
          //console.log("file upload success");
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      setIstLoading(false);
    } catch (error) {
      // alert(JSON.stringify(error));
      console.error(error);
    }
  }

  const img = `https://${
    config.DOMAIN
  }/${config.aws_org_id.toLowerCase()}-resources/images/topic-images/${
    topicData?.tid
  }.png`;

  function renderObjectsext(objType) {
    switch (objType) {
      case "video":
        return "video/*";
      case "audio":
        return "audio/*";
      case "pdf":
        return ".pdf";
      case "html":
        return ".html";
      case "interactivity":
        return ".html, application/zip, application/x-zip-compressed, .zip";
      case "quiz":
        return ".json";
      default:
        return null;
    }
  }

  const renderSwitch = (param) => {
    if (param === "video") {
      return <img src={video} style={{ height: "32px" }} />;
    } else if (param === "audio") {
      return <img src={audio} style={{ height: "32px" }} />;
    } else if (param === "quiz") {
      return <img src={quiz} style={{ height: "32px" }} />;
    } else if (param === "html") {
      return <img src={html} style={{ height: "32px" }} />;
    } else if (param === "Interactivity" || param === "interactivity") {
      return <img src={inter} style={{ height: "32px" }} />;
    } else if (param === "pdf") {
      return <img src={pdf} style={{ height: "32px" }} />;
    } else if (param === "youtube") {
      return <img src={youtube} style={{ height: "32px" }} />;
    } else if (param === "vimeo") {
      return <img src={vimeo} style={{ height: "32px" }} />;
    } else if (param === "scorm") {
      return <img src={scorm} style={{ height: "32px" }} />;
    } else {
      return param;
    }
  };

  const handleClickOpen = () => {
    setNopen(true);
  };

  const handleClose = () => {
    //getCourseDetails();
    setOpen(false);
    editObj.oeditmode = false;
    setEditObj({ ...editObj });
  };

  async function validationcourse() {
    let tdur = 0,
      ndur = 0,
      tobj = 0,
      tvid = 0,
      tdoc = 0,
      tsq = 0,
      tfq = 0;

    //console.log("delete data===", deleteData);
    if (topicData.tn === "" || topicData.tn.length === 0) {
      swal("Oops!", "Course title cannot be empty", "error");
      return;
    }
    if (topicData.tdesc === "" || topicData.tdesc.length === 0) {
      swal(
        "Oops!",
        "Course description cannot be empty in Overview section",
        "error"
      );
      return;
    }
    if (
      topicData?.targetAudience === "" ||
      topicData?.targetAudience?.length === 0
    ) {
      swal(
        "Oops!",
        "Target Audience cannot be empty in Overview section",
        "error"
      );
      setIstLoading(false);
      return;
    }
    if (
      topicData.learningObjectives === "" ||
      topicData?.learningObjectives?.length === 0
    ) {
      swal(
        "Oops!",
        "Learning Objectives cannot be empty in Overview section",
        "error"
      );
      setIstLoading(false);
      return;
    }
    if (topicData.dur === "" || topicData.dur.length === 0) {
      swal("Oops!", "Course duration cannot be empty", "error");
      return;
    }
    if (topicData.dur < 1) {
      swal("Oops!", "Course duration must be greater than 0", "error");
      return;
    }
    if (nuggets.length === 0) {
      swal("Oops!", "You need to add atleast 1 Module.", "error");
      return;
    }
    let nug = [...nuggets];
    nug = produce(nug, (v) => {
      for (let i = 0; i < v.length; i++) {
        ndur = 0;

        for (let o = 0; o < v[i].objects.length; o++) {
          tobj++;
          ndur = ndur + Number(v[i].objects[o].oduration);
          tdur = tdur + Number(v[i].objects[o].oduration);
          if (
            v[i].objects[o].otype === "video" ||
            v[i].objects[o].otype === "audio"
          ) {
            tvid++;
          } else if (v[i].objects[o].otype === "quiz") {
            tsq++;
          } else {
            tdoc++;
          }
          delete v[i].objects[o].url;
          if (o === v[i].objects.length - 1) {
            v[i].nduration = ndur;
          }
          //console.log(ndur);
        }
      }
    });
    setNuggets(nug);
    //console.log(nug);
    handleEditSave(tobj, tvid, tdoc, tsq, tfq, tdur, nug);
  }

  const handleEditSave = async (tobj, tvid, tdoc, tsq, tfq, tdur, nug) => {
    setIstLoading(true);
    let topicjson = { ...topicData };

    topicjson.nuggets = nug;
    topicjson.nugcnt = nug.length;
    topicjson.tobj = tobj;
    topicjson.tvid = tvid;
    topicjson.tdoc = tdoc;
    topicjson.tsq = tsq;
    topicjson.tfq = tfq;
    topicjson.dur = tdur;
    topicjson.targetAudience = topicjson.target_audience;
    topicjson.learningObjectives = topicjson.learning_objectives;

    delete topicjson.learning_objectives;
    delete topicjson.target_audience;
    delete topicjson.Policy;
    delete topicjson.Signature;
    delete topicjson.KeyPairId;
    delete topicjson.topicimg;
    const bodyParam = {
      body: {
        topicjsonchanges: false,
        nuggetchanges: true,
        topicjson,
        topicimgchanges: topImg,
        oid: config.aws_org_id,
        cby: userDetails.name,
        tid: topicjson.tid,
        folder: folder,
        schema: Constants.SCHEMA_NAME,
        coursetype: courseType,
        priceinr: parseInt(priceininr),
        priceusd: parseInt(priceinusd),
        perdis: perDis,
        priceafterDisInr: parseFloat(priceafterDisInr),
        priceafterDisUsd: parseFloat(priceafterDisUsd),
        earlyDate: earlyDate,
        earlyPriceUsd: parseInt(earlyPriceUsd),
        earlyPriceInr: parseInt(earlyPriceInr),
        speakerArray: speakerArray,
        deleteData,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    if (userDetails.curprg !== undefined) {
      if (userDetails.curprg.btid !== undefined) {
        bodyParam.body.pid = userDetails.curprg.pid;
        bodyParam.body.btid = userDetails.curprg.btid;
      } else {
        bodyParam.body.pid = userDetails.curprg.pid;
        //bodyParam.body.btid = userDetails.curprg.pid;
      }
    }
    //console.log("edit====", JSON.stringify(bodyParam.body, null, 2));
    //alert(JSON.stringify(bodyParam.body, null, 2));
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.EDIT_TOPIC,
        bodyParam
      );
      const tab = window.localStorage.getItem("tab");
      history.push(`/uspcontent?tab=${tab}`);
      window.location.reload();
      getTopics();
      breadcrumb();
      getTopicList();
    } catch (error) {
      //console.log("getCategoryError", error);
    }
  };

  function CourseStructure() {
    return (
      <div className={classes.root}>
        {istLoading && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        <Typography component="div" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        {/* <pre>{JSON.stringify(topics, null, 2)}</pre> */}

        <DialogBox
          nuggets={nuggets}
          setNuggets={setNuggets}
          nopen={nopen}
          setNopen={setNopen}
          setIsLoading={setIsLoading}
          topicData={topicData}
          errors={errors}
          setErrors={setErrors}
        />

        {isLoading ? (
          <></>
        ) : (
          <>
            {nuggets.map(
              ({ nid, ntitle, nduration, objects, nenabled }, idx) => (
                <Accordion
                  ref={accRef}
                  id={"acc" + idx}
                  key={idx}
                  style={{ width: "100%" }}
                  expanded={expanded === `${idx}`}
                  onChange={handleAcChange(`${idx}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={{ padding: "0px 28px" }}
                  >
                    {!editObj.meditmode && (
                      <>
                        <h2 className="size-m-b">{ntitle}</h2>
                      </>
                    )}
                    {editObj.meditmode && (
                      <>
                        <Input
                          defaultValue={ntitle}
                          className={Cviewstyle.inputsize}
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          inputProps={{ "aria-label": "description" }}
                          onChange={(e) => {
                            topicData.nuggets[idx].ntitle = e.target.value;
                            setTopicData(topicData);
                          }}
                        />
                        <button
                          onClick={() => {
                            setNuggets((currentTopic) =>
                              produce(currentTopic, (draft) => {
                                draft.splice(idx, 1);
                              })
                            );
                            setDeleteData((currentDt) =>
                              produce(currentDt, (draft) => {
                                draft.nuggets.push(nid);
                              })
                            );
                          }}
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            padding: "0px 20px",
                            background: "#e35f14",
                            border: "none",
                            borderRadius: "5px",
                            width: "194px",
                            color: "#fff",
                          }}
                        >
                          Remove Module
                        </button>{" "}
                      </>
                    )}
                    <p
                      style={{
                        fontSize: "14px",
                        position: "absolute",
                        right: "60px",
                      }}
                    ></p>
                  </AccordionSummary>

                  <div>
                    <div className="display-flex padding2"></div>
                    {objects.map((object, oidx) => (
                      <div
                        className="flex size"
                        style={{
                          width: "calc(100% - 4.5%)",
                          display: "flex",
                          alignItems: "center",
                          margin: "0 0 5px 50px",
                        }}
                      >
                        {renderSwitch(object.otype)}
                        <p style={{ cursor: "pointer" }}>
                          <a
                            style={{ paddingLeft: "10px" }}
                            href
                            onClick={() => {
                              let obj = {};
                              obj.otherdata = {
                                title: object.otitle,
                                desc: object.odescription,
                                dur: object.oduration,
                                files: "",
                              };
                              if (
                                object.oduration !== undefined &&
                                object.oduration !== ""
                              ) {
                                obj.otherdata.min = Math.floor(
                                  (object.oduration % 3600) / 60
                                );
                                obj.otherdata.sec = Math.floor(
                                  (object.oduration % 3600) % 60
                                );
                              }
                              obj.sobject = object.otype;
                              obj.otype = object.otype;
                              obj.oid = object.oid;
                              obj.seltype = true;
                              obj.error1 = false;
                              obj.afile = renderObjectsext(object.otype);
                              obj.oindex = oidx;
                              obj.nindex = idx;

                              obj.ourl = object.ourl;
                              //alert(JSON.stringify(object))
                              if (object.otype === "quiz") {
                                obj.qtype = object.qtype;
                              }
                              setSelectedObj(obj);
                              setOIndex(oidx);
                              setcurObject(object);
                              // setObjectData(object)
                              // if (objects.otype === "quiz") {
                              //   getAssignmentDataPost(object.oid);
                              // }
                              setObjOpen(false);
                              setOpen(true);
                            }}
                          >
                            {object.otitle}
                          </a>
                        </p>
                        <span>
                          {editObj.meditmode && (
                            <button
                              onClick={() => {
                                setNuggets((currentTopic) =>
                                  produce(currentTopic, (draft) => {
                                    draft[idx].objects.splice(oidx, 1);
                                  })
                                );
                                setDeleteData((currentDt) =>
                                  produce(currentDt, (draft) => {
                                    draft.objects.push(object.oid);
                                  })
                                );
                              }}
                              style={{
                                border: "none",
                                background: "transparent",
                              }}
                            >
                              <CloseIcon
                                fontSize="10px"
                                style={{ color: "red" }}
                              />
                            </button>
                          )}
                        </span>
                      </div>
                    ))}
                    {editObj.meditmode && (
                      <div className={Cviewstyle.addnugget}>
                        <div>
                          <p
                            style={{
                              fontSizesize: "14px",
                              margin: "5px 0",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setcNugget({
                                nid,
                                ntitle,
                                nduration,
                                objects,
                                idx,
                                cedit: true,
                              });

                              setObjOpen(true);
                            }}
                          >
                            <img
                              style={{
                                height: "25px",
                                border: "none",
                                cursor: "pointer",
                                margin: "0 15px",
                              }}
                              src={addpluse}
                            />
                            Add objects
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </Accordion>
              )
            )}
            {editObj.meditmode && (
              <div className={Cviewstyle.addnugget}>
                <div>
                  <p
                    style={{
                      fontSizesize: "14px",
                      margin: "5px 0",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleClickOpen}
                  >
                    <img
                      style={{
                        height: "25px",
                        border: "none",
                        cursor: "pointer",
                        margin: "0 15px",
                      }}
                      src={addpluse}
                      alt="Add Module"
                    />
                    Add Module
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  function breadcrumb() {
    let sdata = { ...userDetails };
    let temp = [...sdata.breadcrumb];

    temp[1] = {
      name: userDetails.topic.tn,
      path: "/uspcontent/viewtopic",
    };

    sdata.breadcrumb = temp;

    dispatch(awsSignIn(sdata));
  }

  // const handleAcChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  return (
    <div className={viewTopicStyle.maincontainer}>
      <Breadcrumb Bindex={1} />

      <div className={viewTopicStyle.h1}>
        {/* <h1 className={viewTopicStyle.header}>View Course</h1> */}
        <ObjectPopUp
          objOpen={objOpen}
          setObjOpen={setObjOpen}
          renderSwitch={renderSwitch}
          folder={folder}
          cNugget={cNugget}
          setNuggets={setNuggets}
          nuggets={nuggets}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div
              style={{
                width: "70%",
                backgroundColor: "white",
                borderRadius: "10px",
              }}
            >
              <CoursePlayer
                handleClose={handleClose}
                courseDetails={topicData}
                setCourseDetails={setTopicData}
                objectData={objectData}
                setObjectData={setObjectData}
                oIndex={oIndex}
                setOIndex={setOIndex}
                curObject={curObject}
                setcurObject={setcurObject}
                curObRef={curObRef}
                summative={summative}
                setSummative={setSummative}
                qisLoading={qisLoading}
                setQIsLoading={setQIsLoading}
                editObj={editObj}
                setEditObj={setEditObj}
                renderSwitch={renderSwitch}
                setSelectedObj={setSelectedObj}
                selectedObj={selectedObj}
                setNuggets={setNuggets}
                nuggets={nuggets}
                folder={folder}
                setOpen={setOpen}
                open={open}
              />
            </div>
          </Fade>
        </Modal>
        {!isLoading && editObj.editbtn && (
          <button
            className={viewTopicStyle.savebutton}
            onClick={() => {
              if (topicData?.tenants?.length > 0) {
                swal("Oops!", "Please unpublish the course to edit", "error");
                return;
              } else {
                editObj.meditmode = true;
                editObj.editbtn = false;
                setEditObj({ ...editObj });
              }
            }}
          >
            {" "}
            Edit course
          </button>
        )}
        {editObj.meditmode && (
          <>
            <Button
              onClick={() => {
                swal({
                  title: "Are you sure?",
                  text: "Save the changes",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willsave) => {
                  if (willsave) {
                    // swal("Poof! Your imaginary file has been deleted!", {
                    //   icon: "success",
                    // });
                    validationcourse();
                    setIstLoading(true);
                    swal("Success", "Course Edited Successfully", "success");
                  }
                });
              }}
              className={viewTopicStyle.savebutton}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              style={{ width: "100px", height: "36px", alignSelf: "center" }}
              onClick={() => {
                swal({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((willsave) => {
                  if (willsave) {
                    setIsLoading(true);
                    setTopicData({ ...topicData });
                    editObj.meditmode = false;
                    editObj.editbtn = true;
                    setEditObj({ ...editObj });
                    setIsLoading(false);
                    window.location.reload();
                  }
                });
              }}
            >
              Discard
            </Button>
          </>
        )}
      </div>
      <Box sx={{ typography: "body1" }}>
        <Typography component="box" variant="h1">
          {isLoading ? <Skeleton /> : null}
        </Typography>
        {!isLoading && (
          <div className={Cviewstyle.flex_box} style={{ position: "relative" }}>
            {refselectedCourseFile.current !== undefined ? (
              <img
                className={Cviewstyle.imagebox}
                src={URL.createObjectURL(refselectedCourseFile.current)}
                alt="test logo"
              />
            ) : (
              <img className={Cviewstyle.imagebox} src={img} alt="test logo" />
            )}
            <img src={black} className={Cviewstyle.imgbl} alt="" />
            <Input
              className={Cviewstyle.inputsize + " " + Cviewstyle.ctitle}
              onChange={(e) => {
                topicData.tn = e.target.value;
                setTopicData((prevtopicData) => ({
                  ...prevtopicData,
                  ...topicData,
                }));
              }}
              inputProps={{
                "aria-label": "description",
                placeholder: "Title",
                style: { textAlign: "center" },
              }}
              disabled={!editObj.meditmode}
              value={topicData?.tn || ""}
            />

            <input
              type="file"
              id="cfiles"
              disabled={!editObj.meditmode}
              style={{ visibility: "hidden", position: "absolute" }}
              onChange={changeHandler}
              accept="Image/*"
            />

            <label
              for="cfiles"
              style={{ position: "absolute", right: "10px", bottom: "6px" }}
            >
              <AddAPhotoIcon style={{ color: "#ddd", cursor: "pointer" }} />
            </label>
          </div>
        )}
        <TabContext value={value || "2"}>
          <Box sx={{ borderBottom: 1, backgroundColor: "white" }}>
            <Tabs
              value={value || "2"}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              indicatorColor="primary"
              textColor="inherit"
              variant="fullWidth"
            >
              <Tab label="Overview" value="1" />
              <Tab label="Content" value="2" />
              <Tab label="Preference" value="3" />
            </Tabs>
          </Box>
          <TabPanel
            value="1"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <div>
              <TextareaAutosize
                maxRows={10}
                minRows={5}
                aria-label="maximum height"
                placeholder="Overview of Course"
                defaultValue={topicData.tdesc}
                disabled={!editObj.meditmode}
                style={{ width: "100%", fontSize: "14px", padding: "10px" }}
                onChange={(e) => {
                  topicData.tdesc = e.target.value;
                  setTopicData((prevtopicData) => ({
                    ...prevtopicData,
                    ...topicData,
                  }));
                }}
              />
              <TextareaAutosize
                maxRows={10}
                minRows={5}
                aria-label="maximum height"
                placeholder="Overview of Course"
                defaultValue={topicData.learning_objectives}
                disabled={!editObj.meditmode}
                style={{ width: "100%", fontSize: "14px", padding: "10px" }}
                onChange={(e) => {
                  topicData.learning_objectives = e.target.value;
                  setTopicData((prevtopicData) => ({
                    ...prevtopicData,
                    ...topicData,
                  }));
                }}
              />
              <TextareaAutosize
                maxRows={10}
                minRows={5}
                aria-label="maximum height"
                placeholder="Overview of Course"
                defaultValue={topicData.target_audience}
                disabled={!editObj.meditmode}
                style={{ width: "100%", fontSize: "14px", padding: "10px" }}
                onChange={(e) => {
                  topicData.target_audience = e.target.value;
                  setTopicData((prevtopicData) => ({
                    ...prevtopicData,
                    ...topicData,
                  }));
                }}
              />
            </div>
          </TabPanel>

          <TabPanel
            value="2"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <CourseStructure />
            {/* <ViewTopicContent
              nuggets={nuggets}
              setNugget={setNuggets}
              objectData={objectData}
              topicData={topicData}
              setObjectData={setObjectData}
              setTopicData={setTopicData}
            /> */}
          </TabPanel>
          <TabPanel
            value="3"
            className={classes[".css-13xfq8m-MuiTabPanel-root"]}
          >
            <AddPrefrences
              topics={topics}
              folder={folder}
              topicData={topicData}
              setTopicData={setTopicData}
              editObj={editObj}
              setEditObj={setEditObj}
              priceafterDisInr={priceafterDisInr}
              priceafterDisUsd={priceafterDisUsd}
              setPriceafterDisInr={setPriceafterDisInr}
              setPriceafterDisUsd={setPriceafterDisUsd}
              priceininr={priceininr}
              setPriceininr={setPriceininr}
              priceinusd={priceinusd}
              setPriceinusd={setPriceinusd}
              setEarlyPriceInr={setEarlyPriceInr}
              earlyPriceUsd={earlyPriceUsd}
              setEarlyPriceUsd={setEarlyPriceUsd}
              earlyPriceInr={earlyPriceInr}
              earlyDate={earlyDate}
              setEarlyDate={setEarlyDate}
              perDis={perDis}
              setPerDis={setPerDis}
              speakerType={speakerType}
              setSpeakerType={setSpeakerType}
              setSpeaker={setSpeaker}
              speakerArray={speakerArray}
              setSpeakerArray={setSpeakerArray}
              speakerList={speakerList}
              courseType={courseType}
              setCourseType={setCourseType}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
}
